import React from 'react';
import { Link } from '@reach/router';

import logo from './logo.png';

const styles = {
  link: {
    color: '#000',
    fontSize: 20,
    fontWeight: 600
  },
  logo: {
    display: 'inline-block',
    width: '100%'
  }
};

const Logo = ({ width }: { width?: number }) => (
  <Link to="/" style={styles.link}>
    <img src={logo} style={{ ...styles.logo, maxWidth: width }} alt="Finrei" />
  </Link>
);

Logo.defaultProps = {
  width: 120
};

export default React.memo(Logo);
