export default {
  menus: {
    homepage: {
      vi: 'Trang chủ',
      en: 'Home Page'
    },
    fund_seekers: {
      vi: 'Người gọi vốn',
      en: 'Fund Seekers'
    },
    investors: {
      vi: 'Nhà đầu tư',
      en: 'Investors'
    },
    about_finrei: {
      vi: 'Về FinREI',
      en: 'About FinREI'
    }
  },
  btn_login: {
    vi: 'Đăng nhập',
    en: 'Log In'
  },
  user_dropdown: {
    investor: {
      menu1: {
        vi: 'Quản lý tài khoản',
        en: 'Account manage'
      },
      menu2: {
        vi: 'Trang cá nhân',
        en: 'Profile'
      },
      menu3: {
        vi: 'Đầu tư',
        en: 'Invest'
      },
      menu4: {
        vi: 'Nạp tiền',
        en: 'Add fund'
      },
      menu5: {
        vi: 'Đăng xuất',
        en: 'Log out'
      }
    },
    borrower: {
      menu1: {
        vi: 'Trang cá nhân',
        en: 'Profile'
      },
      menu2: {
        vi: 'Các khoản vay',
        en: 'Loans'
      },
      menu3: {
        vi: 'Khoản vay mới',
        en: 'Add new loan'
      },

      menu4: {
        vi: 'Đăng xuất',
        en: 'Log out'
      }
    }
  }
};
