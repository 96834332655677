import React from 'react';
import { Row, Col, Divider, Icon } from 'antd';
import moment from 'moment';

import { withLang } from '../../hoc';
import { HomeSectionContainer } from '../../pages/Home/style';
import Logo from '../Header/Logo';
import FormPhone from '../FormPhone';
import FormSubs from '../FormSubs';
import { FooterStyle, FooterUl, FooterLinkStyle } from './style';
import content from './content';
import Dmca from '../Dmca';
import lang from './lang';

const Footer = ({ locale }) => {
  return (
    <FooterStyle>
      <HomeSectionContainer>
        <Row gutter={15}>
          <Col md={9}>
            <div
              style={{
                display: 'inline-block',
                padding: 15,
                borderRadius: 10,
                background: '#fff',
                marginBottom: 20
              }}
            >
              <Logo />
            </div>
            <FooterUl>
              <li>{lang.footer0.text1[locale]}</li>
              <li>{lang.footer0.text2[locale]}</li>
              <li>6 Raffles Quay, #11-07,048580, Singapore </li>
              <li>3-5-6-3F, KitaAoyama, Minatoku, 107- 0061, Tokyo, Japan</li>
            </FooterUl>
          </Col>
          <Col md={4}>
            <h5>{lang.footer1.title[locale]}</h5>

            <FooterUl hover>
              {content(locale).links.map(link => {
                return (
                  <li key={link.title}>
                    <FooterLinkStyle to={link.path}>
                      {link.title}
                    </FooterLinkStyle>
                  </li>
                );
              })}
            </FooterUl>
          </Col>
          <Col md={4}>
            <h5>{lang.footer2.title[locale]}</h5>
            <FooterUl hover>
              {content(locale).links1.map(link => {
                return (
                  <li key={link.title}>
                    {link.type === 'a' ? (
                      <a
                        target="_blank"
                        href={link.path}
                        download={link.download}
                      >
                        {link.title}
                      </a>
                    ) : (
                      <FooterLinkStyle to={link.path}>
                        {link.title}
                      </FooterLinkStyle>
                    )}
                  </li>
                );
              })}
            </FooterUl>
          </Col>
          <Col md={7} style={{ textAlign: 'right' }}>
            <h5>{lang.footer3.title[locale]}</h5>

            <FooterUl>
              <li>
                <Icon type="mail" /> info@finrei.com
              </li>
              <li>
                <Icon type="mobile" /> 028 6671 0999 | 092 1900 888
              </li>
              <li style={{ marginBottom: 0 }}>
                <FormPhone locale={locale} />
              </li>
              <li>
                <FormSubs locale={locale} />
              </li>
            </FooterUl>
          </Col>

          <Col md={24}>
            <p>
              <small>(*) {lang.warning[locale]}</small>
            </p>
          </Col>
        </Row>

        <Divider />

        <div
          className="footer-bottom"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <div className="socials">
            <a target="_blank" href="https://www.facebook.com/finrei/">
              <span>
                <Icon type="facebook" />
              </span>
            </a>

            <a target="_blank" href="https://www.linkedin.com/company/finrei/">
              <span>
                <Icon type="linkedin" />
              </span>
            </a>
          </div>
          <Dmca />
          <div>Copyrights © {moment(Date.now()).format('YYYY')} FinREI</div>
        </div>
      </HomeSectionContainer>
    </FooterStyle>
  );
};

export default withLang(Footer);
