// @flow
import axios from './axios';

export default {
  provinces: () => axios.get('/provinces'),
  districts: (provinceId: string) =>
    axios.get(`/districts?province_master_id=${provinceId}`),
  propertyUnitTypes: () => axios.get('/property_unit_types'),
  loanPurposes: () => axios.get('/loan_purposes'),
  banks: () => axios.get('/banks'),
  entity_types: () => axios.get('/entity_types'),
  relationship: () => axios.get('/relationship_types'),
  occupations: () => axios.get('/occupations'),
  investment_types: () => axios.get('/investment_types'),
  investment_experience_years: () => axios.get('/investment_experience_years'),
  business_types: () => axios.get('/business_types'),
  position_types: () => axios.get('/position_types'),
  lending_pages: () => axios.get('/lending_pages')
};
