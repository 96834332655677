import React, { useContext } from 'react';
import { Avatar, Dropdown, Menu, Icon } from 'antd';

import { withLang } from '../../hoc';
import { authContext } from '../../context';
import {
  HeaderLeft,
  MenuItemStyle,
  MenuItemLinkStyle,
  HeaderRight
} from './style';
import Logo from './Logo';
// import Phone from './Phone';
import Nav from './Nav';
import ButtonLogin from './ButtonLogin';
import SelectLang from './SelectLang';
import lang from './lang';

const HeaderLarge = ({ locale }) => {
  const { user, logout } = useContext(authContext);

  // const menu_borrower1 = (
  //   <Menu>
  //     <Menu.Item key="0">
  //       <Link to="/borrower/profile">Trang cá nhân</Link>
  //     </Menu.Item>

  //     <Menu.Item key="1">
  //       <Link to="/borrower/loans">Các khoản vay</Link>
  //     </Menu.Item>
  //     <Menu.Item key="2">
  //       <Link to="/loan/new">Khoản vay mới</Link>
  //     </Menu.Item>

  //     <Menu.Divider />
  //     <Menu.Item key="3" onClick={logout}>
  //       Đăng xuất
  //     </Menu.Item>
  //   </Menu>
  // );

  const menu_borrower = (
    <Menu>
      <MenuItemLinkStyle to="/borrower/profile">
        {lang.user_dropdown.borrower.menu1[locale]}
      </MenuItemLinkStyle>

      <MenuItemLinkStyle to="/borrower/loans">
        {lang.user_dropdown.borrower.menu2[locale]}
      </MenuItemLinkStyle>

      <MenuItemLinkStyle to="/loan/new">
        {lang.user_dropdown.borrower.menu3[locale]}
      </MenuItemLinkStyle>

      <MenuItemStyle onClick={logout}>
        {lang.user_dropdown.borrower.menu4[locale]}
      </MenuItemStyle>
    </Menu>
  );

  const menu_investor = (
    <Menu>
      <MenuItemLinkStyle to="/investor/dashboard">
        {lang.user_dropdown.investor.menu1[locale]}
      </MenuItemLinkStyle>

      <MenuItemLinkStyle to="/investor/profile">
        {lang.user_dropdown.investor.menu2[locale]}
      </MenuItemLinkStyle>

      <MenuItemLinkStyle to="/investor/dau-tu">
        {lang.user_dropdown.investor.menu3[locale]}
      </MenuItemLinkStyle>

      <MenuItemLinkStyle to="/investor/add-fund">
        {lang.user_dropdown.investor.menu4[locale]}
      </MenuItemLinkStyle>
      <MenuItemStyle onClick={logout}>
        {lang.user_dropdown.investor.menu5[locale]}
      </MenuItemStyle>
    </Menu>
  );

  // const menu_investor1 = (
  //   <Menu>
  //     <Menu.Item key="0">
  //       <Link to="/investor/dashboard">Quản lý tài khoản</Link>
  //     </Menu.Item>

  //     <Menu.Item key="1">
  //       <Link to="/investor/profile">Trang cá nhân</Link>
  //     </Menu.Item>

  //     <Menu.Item key="2">
  //       <Link to="/investor/dau-tu">Đầu tư</Link>
  //     </Menu.Item>
  //     <Menu.Item key="3">
  //       <Link to="/investor/add-fund">Nạp tiền</Link>
  //     </Menu.Item>

  //     <Menu.Divider />
  //     <Menu.Item key="4" onClick={logout}>
  //       Đăng xuất
  //     </Menu.Item>
  //   </Menu>
  // );

  const renderRightHeader = () => {
    if (user) {
      const menu = user.role === 'investor' ? menu_investor : menu_borrower;

      return (
        <Dropdown overlay={menu} trigger={['click']}>
          <div style={{ cursor: 'pointer' }}>
            <Avatar size="large" icon="user" /> <Icon type="down" />
          </div>
        </Dropdown>
      );
    }

    return <ButtonLogin />;
  };

  return (
    <>
      <HeaderLeft>
        <Logo width={150} />

        {/* <Phone /> */}
        <Nav />
      </HeaderLeft>
      <HeaderRight>
        <SelectLang />
        <div style={{ marginLeft: 30 }}>{renderRightHeader()}</div>
      </HeaderRight>
    </>
  );
};

export default withLang(HeaderLarge);
