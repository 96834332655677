import styled from 'styled-components';
import { Link } from '@reach/router';
import { theme } from '../../configs';

const FooterStyle = styled.footer`
  font-size: 16px;
  font-weight: 600;
  // background: #f4f4f4;
  background: #7b61d6;
  padding: 50px 30px;
  color: #fff;

  h5 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 15px;
    color: #33e2a0;
    line-height: 72px;
  }

  .footer-bottom {
    padding-bottom: 20px;
  }

  .socials {
    span {
      display: inline-block;
      margin-right: ${theme.size.space - 5}px;
      font-size: 25px;
      position: relative;
      color: #ddd;

      &:hover {
        color: #fff;
      }

      &:after {
        content: '';
        display: inline-block;
        height: 5px;
        width: 5px;
        border-radius: 50%;
        background: #7c60d5;
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -2.5px;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }
`;

const FooterUl = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin-bottom: 15px;
    display: block;
    cursor: pointer;
    transition: all 0.3s ease-out 0s;
    font-size: 14px;

    a {
      color: #fff;
      font-size: 14px;
    }

    &:hover {
      padding-left: ${props => (props.hover ? '2px' : 0)};
      &:before {
        content: '';
        background: #7c60d5;
        height: 6px;
        width: 6px;
        position: relative;
        display: ${props => (props.hover ? 'inline-block' : 'none')};
        border-radius: 90px;
        line-height: 26px;
        vertical-align: middle;
        right: 10px;
      }
    }
  }
`;

const FooterLinkStyle = styled(Link)`
  color: #333;
  font-size: 16px;
  font-weight: 600;

  &:hover {
    color: #33e2a0;
  }
`;

export { FooterStyle, FooterUl, FooterLinkStyle };
