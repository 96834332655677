import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  InputNumber,
  Radio,
  Button,
  Row,
  Col,
  Card,
  Select,
  message
} from 'antd';
import { toNumber } from 'lodash';
import { Link } from '@reach/router';

import { withLang, withAuth } from '../../hoc';
import api from '../../api';
import { WrapForm, StyleBox } from './style';
import { removeAlias } from './ultil';
import { useProvinces } from '../../pages/InvestorProfileComplete/useHooks';
import lang from './lang';
import { useBanks, useEntityTypes } from './useHooks';

const FormItem = Form.Item;
const { Option } = Select;
const size = 'large';

type BorrowerProps = {
  form: any,
  cb: Function,
  updateLoan: Function,
  loan: any,
  user: any,
  locale: string,
  isNew: boolean
};

const Borrower = ({
  form,
  cb,
  loan,
  updateLoan,
  locale,
  user,
  isNew
}: BorrowerProps) => {
  const [loading, setLoading] = useState(false);
  const [typeBorrower, setTypeBorrower] = useState(null);
  const [isBankDebt, setIsBankDebt] = useState(false);
  const [provinces] = useProvinces();
  const banks = useBanks();
  const entity_types = useEntityTypes();

  const initialValue = {
    // borrower_type: null,
    // family_name: null,
    // middle_name: null,
    // given_name: null,
    // email: null,
    // phone_number: null,
    province_master_id: null,
    // is_bank_debt: null,
    outstanding_balance: null,
    // bank_master_id: null,
    entity_name: null,
    entity_type_master_id: null,
    entity_establishment_year: null,
    entity_charter_capital: null,
    entity_temporarily_suspend_operation_in_past: false
  };

  const reloadLoan = async () => {
    try {
      const { token } = user;
      const { id } = loan;

      const res = await api.loanApi.loan(id, token);
      message.success('Cập nhật thông tin người vay thành công');
      setLoading(false);
      updateLoan(res.data);
      cb('guarantor_profile');
    } catch (error) {
      message.error('Xảy ra lỗi, vui lòng kiểm tra lại');
      setLoading(false);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();

    const { validateFields } = form;

    validateFields((err, values) => {
      if (!err) {
        const data = {
          borrower_profile: {
            ...initialValue,
            ...values,
            province_master_id: toNumber(values.province_master_id)
          }
        };

        const { token } = user;

        api.borrowerApi
          .updateBorrowerProfile({ token, data })
          .then(() => {
            reloadLoan();
          })
          .catch(() => {
            message.error('Xảy ra lỗi, vui lòng kiểm tra lại');
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    });
  };

  const selectTypeBorrower = e => {
    setTypeBorrower(e.target.value);
  };

  const { getFieldDecorator } = form;

  useEffect(() => {
    if (
      loan &&
      loan.borrower_profile &&
      loan.borrower_profile.borrower_type === 'individual'
    ) {
      setTypeBorrower(0);
    } else if (
      loan &&
      loan.borrower_profile &&
      loan.borrower_profile.borrower_type === 'entity'
    ) {
      setTypeBorrower(1);
    } else {
      setTypeBorrower(null);
    }

    if (
      loan &&
      loan.borrower_profile &&
      loan.borrower_profile.borrower_type === 'individual'
    ) {
      if (loan && loan.borrower_profile) {
        setIsBankDebt(!!loan.borrower_profile.is_bank_debt);
      }
    }

    // loadProvinces();
  }, [loan]);

  const initialValueBorrowerType = () => {
    if (
      loan &&
      loan.borrower_profile &&
      loan.borrower_profile.borrower_type === 'individual'
    ) {
      return 0;
    }

    if (
      loan &&
      loan.borrower_profile &&
      loan.borrower_profile.borrower_type === 'entity'
    ) {
      return 1;
    }

    return undefined;
  };

  const handleFilter = (input, option) => {
    const text = removeAlias(option.props.children).toLowerCase();

    return text.indexOf(removeAlias(input.toLowerCase())) >= 0;
  };

  const selectBankDebt = e => {
    setIsBankDebt(e.target.value);
    // loadBanks();
  };

  return (
    <WrapForm>
      <Card
        title={
          <div style={{ fontSize: 18 }}>Thông tin về Người cần gọi Vốn</div>
        }
        type="inner"
      >
        <h3>Bạn là</h3>

        <Form onSubmit={handleSubmit}>
          <FormItem>
            {getFieldDecorator('borrower_type', {
              initialValue: initialValueBorrowerType(),
              rules: [
                {
                  required: true,
                  message: 'Vui lòng chọn'
                }
              ]
            })(
              <Radio.Group onChange={selectTypeBorrower}>
                <Radio value={0}>Cá nhân</Radio>
                <Radio value={1}>Doanh nghiệp</Radio>
              </Radio.Group>
            )}
          </FormItem>

          {typeBorrower === 0 && (
            <>
              <Row gutter={15}>
                <Col span={12}>
                  <FormItem label="Họ">
                    {getFieldDecorator('given_name', {
                      initialValue:
                        user && user.given_name ? user.given_name : null,
                      rules: [
                        {
                          required: true,
                          message: 'Vui lòng điền tên'
                        }
                      ]
                    })(<Input size={size} />)}
                  </FormItem>
                </Col>

                <Col span={12}>
                  <FormItem label="Tên">
                    {getFieldDecorator('family_name', {
                      initialValue:
                        user && user.family_name ? user.family_name : null,
                      rules: [
                        {
                          required: true,
                          message: 'Vui lòng điền họ'
                        }
                      ]
                    })(<Input size={size} />)}
                  </FormItem>
                </Col>
              </Row>

              <FormItem label="Email">
                {getFieldDecorator('email', {
                  initialValue: user && user.email ? user.email : null,
                  rules: [
                    {
                      required: true,
                      message: 'Vui lòng điền địa chỉ email'
                    }
                  ]
                })(<Input size={size} />)}
              </FormItem>

              <FormItem label="Số điện thoại">
                {getFieldDecorator('phone_number', {
                  initialValue:
                    user && user.phone_number ? user.phone_number : null,
                  rules: [
                    {
                      required: true,
                      message: 'Điền số điện thoại'
                    }
                  ]
                })(<Input size={size} />)}
              </FormItem>

              <FormItem label="Hộ khẩu thường trú">
                {getFieldDecorator('province_master_id', {
                  initialValue:
                    loan &&
                    loan.borrower_profile &&
                    loan.borrower_profile.province_data
                      ? loan.borrower_profile.province_data.id
                      : undefined,
                  rules: [
                    {
                      required: true,
                      message: 'Chọn hộ khẩu thường trú'
                    }
                  ]
                })(
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Chọn thành phố"
                    optionFilterProp="children"
                    size={size}
                    filterOption={(input, option) =>
                      handleFilter(input, option)
                    }
                  >
                    {provinces.map(p => (
                      <Option key={p.id} value={p.id}>
                        {p.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>

              <h3>Tình hình Tài chính cá nhân</h3>
              <FormItem label="Nợ ngân hàng">
                {getFieldDecorator('is_bank_debt', {
                  initialValue:
                    loan && loan.borrower_profile
                      ? loan.borrower_profile.is_bank_debt
                      : undefined,
                  rules: [
                    {
                      required: true,
                      message: 'Vui lòng chọn'
                    }
                  ]
                })(
                  <Radio.Group onChange={selectBankDebt}>
                    <Radio value>Có</Radio>
                    <Radio value={false}>Không</Radio>
                  </Radio.Group>
                )}
              </FormItem>

              {isBankDebt && (
                <StyleBox style={{ marginBottom: 30 }}>
                  <FormItem label="Số tiền đang nợ">
                    {getFieldDecorator('outstanding_balance', {
                      initialValue:
                        loan && loan.borrower_profile
                          ? loan.borrower_profile.outstanding_balance
                          : undefined,
                      rules: [
                        {
                          required: true,
                          message: 'Chọn số tiền đang nợ'
                        }
                      ]
                    })(
                      <InputNumber
                        formatter={value =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        style={{ width: '100%' }}
                        size={size}
                      />
                    )}
                  </FormItem>
                  <FormItem label="Tên ngân hàng">
                    {getFieldDecorator('bank_master_id', {
                      initialValue:
                        loan &&
                        loan.borrower_profile &&
                        loan.borrower_profile.bank_data
                          ? `${loan.borrower_profile.bank_data.id}`
                          : undefined,
                      rules: [
                        {
                          required: true,
                          message: 'Chọn ngân hàng'
                        }
                      ]
                    })(
                      <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Chọn ngân hàng"
                        optionFilterProp="children"
                        size={size}
                        filterOption={(input, option) =>
                          handleFilter(input, option)
                        }
                      >
                        {banks.map(b => (
                          <Option key={b.id}>{b.name}</Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                </StyleBox>
              )}
            </>
          )}

          {typeBorrower === 1 && (
            <>
              <FormItem label="Tên doanh nghiệp">
                {getFieldDecorator('entity_name', {
                  initialValue:
                    loan && loan.borrower_profile
                      ? loan.borrower_profile.entity_name
                      : undefined,
                  rules: [
                    {
                      required: true,
                      message: 'Điền tên doanh nghiệp'
                    }
                  ]
                })(<Input size={size} />)}
              </FormItem>
              <FormItem label="Loại hình doanh nghiệp">
                {getFieldDecorator('entity_type_master_id', {
                  initialValue:
                    loan &&
                    loan.borrower_profile &&
                    loan.borrower_profile.entity_type_data
                      ? loan.borrower_profile.entity_type_data.id
                      : undefined,
                  rules: [
                    {
                      required: true,
                      message: 'Chọn loại hình doanh nghiệp'
                    }
                  ]
                })(
                  <Select
                    style={{ width: '100%' }}
                    optionFilterProp="children"
                    size={size}
                  >
                    {entity_types.map(ent => (
                      <Option key={ent.id} value={ent.id}>
                        {ent.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              <FormItem label="Năm thành lập của doanh nghiệp">
                {getFieldDecorator('entity_establishment_year', {
                  initialValue:
                    loan &&
                    loan.borrower_profile &&
                    loan.borrower_profile.entity_establishment_year
                      ? loan.borrower_profile.entity_establishment_year
                      : undefined,
                  rules: [
                    {
                      required: true,
                      message: 'Vui lòng điền năm thành lập của doanh nghiệp'
                    }
                  ]
                })(<InputNumber size={size} />)}
              </FormItem>
              <FormItem label="Vốn điều lệ">
                {getFieldDecorator('entity_charter_capital', {
                  initialValue:
                    loan &&
                    loan.borrower_profile &&
                    loan.borrower_profile.entity_charter_capital
                      ? loan.borrower_profile.entity_charter_capital
                      : undefined,
                  rules: [
                    {
                      required: true,
                      message: 'Vui lòng điền vốn điều lệ'
                    }
                  ]
                })(
                  <InputNumber
                    size={size}
                    style={{ width: '100%' }}
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                )}
              </FormItem>
              <FormItem label="Doanh nghiệp đã từng xin tạm ngưng hoạt động thời điểm nào tính từ khi mới thành lập">
                {getFieldDecorator(
                  'entity_temporarily_suspend_operation_in_past',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Vui lòng chọn'
                      }
                    ]
                  }
                )(
                  <Radio.Group>
                    <Radio value>Có</Radio>
                    <Radio value={false}>Không</Radio>
                  </Radio.Group>
                )}
              </FormItem>
            </>
          )}

          <FormItem>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={loading}
            >
              {lang.save_continue[locale]}
            </Button>
            {!isNew && (
              <Link to="/borrower/loans">
                <Button style={{ marginLeft: 10 }}>Danh sách khoản vay</Button>
              </Link>
            )}
          </FormItem>
        </Form>
      </Card>
    </WrapForm>
  );
};

export default Form.create()(withLang(withAuth(Borrower)));
