import styled from 'styled-components';

const HeadStyle = styled.div`
  background: #eee;
  position: relative;

  h2 {
    font-size: 40px;

    @media (max-width: 767px) {
      font-size: 25px;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 15px 0 0;
    display: flex;

    li {
      margin-right: 10px;
      color: #7c60d5;

      a {
        color: #868894;
        &:hover {
          color: #7c60d5;
        }
      }

      &:last-child {
        &:after {
          display: none;
        }
      }

      &:after {
        content: '';
        display: inline-block;
        height: 6px;
        width: 6px;
        background: #7c60d5;
        margin-left: 10px;
        border-radius: 10px;
      }
    }
  }
`;

const HeaderStyle = styled.div`
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

const HeadImg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  img {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

const HeadContent = styled.div`
  padding: 30px 0;

  img {
    display: block;
    width: 100%;

    @media (max-width: 767px) {
      display: none;
    }
  }
`;

export { HeadStyle, HeadImg, HeaderStyle, HeadContent };
