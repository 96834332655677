import styled from 'styled-components';
import { theme } from '../../configs';

const ButtonStyle = styled.div`
  color: #ffffff;
  cursor: pointer;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  text-transform: capitalize;
  font-weight: 700;
  border: none;
  position: relative;
  background: ${({ disabled }) => (disabled ? '#e8e8e8' : '#33e2a0')};
  display: inline-block;
  border-radius: 90px;
  height: ${props =>
    props.height ? `${props.height}px` : `${theme.size.button}px`};
  overflow: hidden;

  > div {
    padding: 0 ${theme.size.space * 2}px;
    line-height: ${props =>
      props.height ? `${props.height}px` : `${theme.size.button}px`};
    transition: all 0.3s ease-out;
  }

  .second {
    background: #1b0e3d;
  }

  &:hover {
    .first {
      margin-top: ${({ disabled, height }) => {
        const height_btn = height || theme.size.button;
        return disabled ? 0 : `-${height_btn}px`;
      }};
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { ButtonStyle };
