// @flow
import React, { useContext, type Node } from 'react';
import { Redirect } from '@reach/router';
import { message } from 'antd';

import authContext from '../context/authContext';
import Error from '../pages/Error';

type RouterProps = { component: any };

// user router
class ErrorBoundary extends React.Component<{ children: Node }> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      // You can render any custom fallback UI
      return <Error />;
    }
    return children;
  }
}

const UserRouter = ({ component: Component, ...props }: RouterProps) => {
  const { authentication } = useContext(authContext);

  if (!authentication) return <Redirect to="/signin" noThrow />;

  return (
    <ErrorBoundary>
      <Component {...props} />;
    </ErrorBoundary>
  );
};

// guest router
const GuestRouter = ({ component: Component, ...props }: RouterProps) => {
  const { authentication } = useContext(authContext);

  if (authentication) return <Redirect to="/" noThrow />;

  return (
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  );
};

// borrower
const BorrowerRouter = ({ component: Component, ...props }: RouterProps) => {
  const { user, authentication } = useContext(authContext);

  if (!authentication) {
    // message.warn('Bạn cần đăng nhập để sử dụng');
    return <Redirect to="/signup" noThrow />;
  }
  if (user && user.role !== 'borrower') return <Redirect to="/" noThrow />;

  return (
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  );
};

//
const InvestorRouter = ({ component: Component, ...props }: RouterProps) => {
  const { user, authentication } = useContext(authContext);

  if (!authentication) {
    // message.warn('Bạn cần đăng nhập để sử dụng');
    return <Redirect to="/signup" noThrow />;
  }

  if (user && user.role !== 'investor') return <Redirect to="/" noThrow />;

  return (
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  );
};

const InvestorVerifyRouter = ({
  component: Component,
  ...props
}: RouterProps) => {
  const { user } = useContext(authContext);

  if (user && !user.is_verified) {
    message.warning('Tài khoản đang được phê duyệt, vui lòng chờ');
    return <Redirect to="/" noThrow />;
  }

  return (
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  );
};

export {
  UserRouter,
  GuestRouter,
  BorrowerRouter,
  InvestorRouter,
  InvestorVerifyRouter
};
