import React, { useState, useEffect, memo } from 'react';
import { Form, Radio, Button, Select, Card, message } from 'antd';
import { Link } from '@reach/router';

import api from '../../api';
import { withLang, withAuth } from '../../hoc';
import { WrapForm, StyleBox } from './style';
import lang from './lang';
import { useRelationship, useOccupations } from './useHooks';

const FormItem = Form.Item;
const { Option } = Select;
const size = 'large';

type GuarantorProps = {
  form: {
    getFieldDecorator: Function,
    validateFields: Function,
    getFieldsValue: Function,
    setFieldsValue: Function
  },
  cb: Function,
  updateLoan: Function,
  loan: any,
  locale: string,
  user: any,
  isNew: boolean
};

const Guarantor = ({
  form,
  loan,
  cb,
  updateLoan,
  locale,
  user,
  isNew
}: GuarantorProps) => {
  const [loading, setLoading] = useState(false);
  const [isGuarantor, setIsGuarantor] = useState(false);
  const [relationships] = useRelationship();
  const [occupations] = useOccupations();

  const { getFieldDecorator } = form;

  const initialValue = {
    has_guarantor: null,
    guarantor_profile: {
      relationship_type: null,
      occupation_master_id: null
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    const { validateFields } = form;

    validateFields((err, values) => {
      if (!err) {
        const { has_guarantor, ...rest } = values;

        const data = {
          ...initialValue,
          loan_id: loan.id,
          has_guarantor,
          guarantor_profile: {
            ...initialValue.guarantor_profile,
            ...rest
          }
        };

        api.borrowerApi
          .updateBorrowerGuarantor({ token: user.token, data })
          .then(res => {
            updateLoan(res.data);
            setLoading(false);
            message.success('Cập nhật thành công người bảo trợ');
            cb('experience');
          })
          .catch(() => {
            message.error('Đã xảy ra lỗi, vui lòng cập nhật lại');
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    });
  };

  const handleIsGuarantor = e => {
    setIsGuarantor(e.target.value);
  };

  useEffect(() => {
    if (loan) {
      setIsGuarantor(!!loan.guarantor_profile);
    }
  }, [loan]);

  return (
    <WrapForm>
      <Card
        type="inner"
        title={<div style={{ fontSize: 18 }}>Người bảo trợ</div>}
      >
        <Form onSubmit={handleSubmit}>
          <h3>Người bảo trợ cho khoản vay</h3>
          <FormItem>
            {getFieldDecorator('has_guarantor', {
              // initialValue: loan && loan.guarantor_profile,
              initialValue: loan && loan.has_guarantor,

              rules: [
                {
                  required: true,
                  message: 'Vui lòng chọn'
                }
              ]
            })(
              <Radio.Group onChange={handleIsGuarantor}>
                <Radio value>Có</Radio>
                <Radio value={false}>Không</Radio>
              </Radio.Group>
            )}
          </FormItem>

          {isGuarantor && (
            <StyleBox style={{ marginBottom: 32 }}>
              <FormItem label={<b>Quan hệ với bạn</b>}>
                {getFieldDecorator('relationship_type', {
                  initialValue:
                    loan && loan.guarantor_profile
                      ? loan.guarantor_profile.relationship_type
                      : undefined,
                  rules: [
                    {
                      required: true,
                      message: 'Chọn mối quan hệ với người bảo trợ'
                    }
                  ]
                })(
                  <Select size={size} placeholder="Chọn mối quan hệ">
                    {relationships.map(r => (
                      <Option value={r.name} key={r.id}>
                        {r.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              <FormItem label={<b>Nghề nghiệp người bảo trợ</b>}>
                {getFieldDecorator('occupation_master_id', {
                  initialValue:
                    loan &&
                    loan.guarantor_profile &&
                    loan.guarantor_profile.occupation_data
                      ? loan.guarantor_profile.occupation_data.id
                      : undefined,
                  rules: [
                    {
                      required: true,
                      message: 'Vui lòng chọn người bảo trợ'
                    }
                  ]
                })(
                  <Select size={size} placeholder="Chọn nghề nghiệp">
                    {occupations.map(r => (
                      <Option value={r.id} key={r.id}>
                        {r.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </StyleBox>
          )}

          <FormItem>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={loading}
            >
              {lang.save_continue[locale]}
            </Button>
            {!isNew && (
              <Link to="/borrower/loans">
                <Button style={{ marginLeft: 10 }}>Danh sách khoản vay</Button>
              </Link>
            )}
          </FormItem>
        </Form>
      </Card>
    </WrapForm>
  );
};

export default memo(Form.create()(withLang(withAuth(Guarantor))));
