import React from 'react';

import TabHeading from './TabHeading';
import lang from './lang';
import Property from './Property';
import Loan from './Loan';
import Borrower from './Borrower';
import Guarantor from './Guarantor';
import Experience from './Experience';

const tabs = ({ cb, locale, loan, updateLoan, isNew }) => [
  {
    key: 1,
    tab: 'property',
    title: (
      <TabHeading
        title={lang.property[locale]}
        active={loan && loan.loan_steps_status.property}
      />
    ),
    component: (
      <Property cb={cb} loan={loan} updateLoan={updateLoan} isNew={isNew} />
    )
  },
  {
    key: 2,
    tab: 'loan',
    title: (
      <TabHeading
        title={lang.loan[locale]}
        active={loan && loan.loan_steps_status.loan}
      />
    ),
    component: <Loan cb={cb} loan={loan} updateLoan={updateLoan} />
  },
  {
    key: 3,
    tab: 'borrower_profile',
    title: (
      <TabHeading
        title={lang.borrower[locale]}
        active={loan && !!loan.loan_steps_status.borrower_profile}
      />
    ),
    component: <Borrower cb={cb} loan={loan} updateLoan={updateLoan} />
  },
  {
    key: 4,
    tab: 'guarantor_profile',
    title: (
      <TabHeading
        title={lang.guarantor[locale]}
        active={loan && loan.loan_steps_status.guarantor_profile}
      />
    ),
    component: <Guarantor cb={cb} loan={loan} updateLoan={updateLoan} />
  },
  {
    key: 5,
    tab: 'experience',
    title: (
      <TabHeading
        title={lang.experience[locale]}
        active={loan && loan.loan_steps_status.experience}
      />
    ),
    component: <Experience cb={cb} loan={loan} updateLoan={updateLoan} />
  }
];

export default tabs;
