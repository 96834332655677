// @flow
import React, { useState } from 'react';
import { Form, Input, Row, Col, Button, Select, message } from 'antd';

import { withAuth } from '../../hoc';
import api from '../../api';
import { useProvinces } from '../InvestorProfileComplete/useHooks';
import { removeAlias } from '../../components/StepLoan/ultil';

const FormItem = Form.Item;
const { Option } = Select;

const size = 'large';

type ProfileFormProps = {
  form: {
    getFieldDecorator: Function,
    validateFields: Function
  },
  user: any
};

const ProfileForm = ({ form, user }: ProfileFormProps) => {
  const { getFieldDecorator, validateFields } = form;
  const [provinces] = useProvinces();

  const [loading, setLoading] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();

    validateFields((err, values) => {
      if (!err) {
        setLoading(true);
        const { token } = user;
        const data = { borrower_profile: { ...values } };

        api.borrowerApi
          .updateBorrowerProfile({ token, data })
          .then(() => {
            message.success('Cập nhật thành công thông tin cá nhân');
            setLoading(false);
          })
          .catch(() => {
            message.error('Đã xảy ra lỗi, vui lòng kiểmt ra lại');
            setLoading(false);
          });
      }
    });
  };

  const handleFilter = (input, option) => {
    const text = removeAlias(option.props.children).toLowerCase();

    return text.indexOf(removeAlias(input.toLowerCase())) >= 0;
  };

  const initialProvince = provinces.find(
    p => p.name_with_type === user.permanent_address
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={30}>
        <Col span={12}>
          <FormItem label="Họ">
            {getFieldDecorator('family_name', {
              initialValue: user.family_name,
              rules: [
                {
                  required: true,
                  message: 'Vui lòng điền họ'
                }
              ]
            })(<Input size={size} />)}
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label="Tên">
            {getFieldDecorator('given_name', {
              initialValue: user.given_name,
              rules: [
                {
                  required: true,
                  message: 'Vui lòng điền tên'
                }
              ]
            })(<Input size={size} />)}
          </FormItem>
        </Col>
      </Row>
      <FormItem label="Số điện thoại">
        {getFieldDecorator('phone_number', {
          initialValue: user.phone_number,
          rules: [
            {
              required: true,
              message: 'Vui điền số điện thoại của bạn'
            }
          ]
        })(<Input size={size} />)}
      </FormItem>

      <FormItem label="Email">
        {getFieldDecorator('email', {
          initialValue: user.email
        })(<Input size={size} disabled />)}
      </FormItem>

      <FormItem label="Hộ khẩu thường trú">
        {getFieldDecorator('province_master_id', {
          initialValue: initialProvince ? initialProvince.id : undefined,
          rules: [
            {
              required: true,
              message: 'Chọn hộ khẩu thường trú'
            }
          ]
        })(
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Chọn thành phố"
            optionFilterProp="children"
            size={size}
            filterOption={(input, option) => handleFilter(input, option)}
          >
            {provinces.map(p => (
              <Option key={p.id} value={p.id}>
                {p.name_with_type}
              </Option>
            ))}
          </Select>
        )}
      </FormItem>

      <FormItem>
        <Button size={size} type="primary" htmlType="submit" loading={loading}>
          Cập nhật
        </Button>
      </FormItem>
    </Form>
  );
};

export default Form.create()(withAuth(ProfileForm));
