// @flow
import React from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { Spin } from 'antd';

import { GoogleMapWrap } from './style';
import { useLocation } from './useHooks';
import { constants } from '../../configs';

const mapStyles = {
  width: '100%',
  height: '100%'
};

type GoogleMapProps = { address: String, google: any };

const GoogleMap = ({ google, address }: GoogleMapProps) => {
  const [loading, center] = useLocation({ google, address });

  const renderMap = () => {
    if (loading)
      return (
        <div
          style={{
            padding: 100,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Spin />
        </div>
      );

    return (
      <Map
        google={google}
        zoom={14}
        style={mapStyles}
        initialCenter={{ ...center }}
      >
        <Marker position={{ ...center }} />
      </Map>
    );
  };

  return <GoogleMapWrap>{renderMap()}</GoogleMapWrap>;
};

export default GoogleApiWrapper({
  apiKey: constants.GOOGLE_API_KEY
})(GoogleMap);
