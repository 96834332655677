import styled from 'styled-components';

const FormSubsStyle = styled.div`
  position: relative;

  .btnForm {
    position: absolute;
    right: 2.5px;
    top: 2.5px;
    bottom: 2.5px;
  }
`;

const inputStyle = {
  width: '100%',
  paddingRight: '-35%',
  height: 40,
  background: '#fff',
  border: `1px solid #e4e4e4`,
  fontSize: 16,
  marginBottom: 15,
  borderRadius: 90,
  boxShadow: ` 0px 13px 25px 0px rgba(77, 54, 206, 0.2)`,
  position: 'relative'
};

export { FormSubsStyle, inputStyle };
