import React from 'react';
import Media from 'react-media';
import { theme } from '../../configs';
import { HeaderWrap } from './style';

import HeaderLarge from './HeaderLarge';
import HeaderSmall from './HeaderSmall';

const Header = () => {
  return (
    <HeaderWrap>
      <Media query={{ maxWidth: theme.media.sm }}>
        {matches => (matches ? <HeaderSmall /> : <HeaderLarge />)}
      </Media>
    </HeaderWrap>
  );
};

export default Header;
