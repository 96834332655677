// @flow
import React, { useState } from 'react';
import { Form, Input, Button, message, Alert } from 'antd';
import api from '../../api';
import { withAuth } from '../../hoc';
import { theme } from '../../configs';

const { Password } = Input;

const size = 'large';

type ChangePasswordProps = {
  user: {
    token: String
  },
  form: any
};

const ChangePassword = ({ user, form }: ChangePasswordProps) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const { getFieldDecorator, validateFields } = form;

  const handleSubmit = e => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        setLoading(true);
        const { token } = user;

        const data = values;
        api.authApi
          .changePassword(token, data)
          .then(() => {
            message.success('Mật khẩu đã được thay đổi');
            setLoading(false);
          })
          .catch(error => {
            setErrors(error.response.data.errors);
            setLoading(false);
          });
      }
    });
  };

  const removeError = () => setErrors([]);

  const renderErrors = () => {
    if (errors.length === 0) {
      return null;
    }

    const renderErrorField = () =>
      errors.map(error => (
        <Alert
          key={error.field}
          message={error.message}
          type="error"
          showIcon
          closable
          onClose={removeError}
        />
      ));
    return (
      <div style={{ marginBottom: theme.size.space }}>{renderErrorField()}</div>
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      {renderErrors()}

      <Form.Item label="Mật khẩu hiện tại">
        {getFieldDecorator('current_password', {
          rules: [
            {
              required: true,
              message: 'Vui lòng điền tên mật khẩu hiện tại'
            }
          ]
        })(<Password size={size} />)}
      </Form.Item>
      <Form.Item label="Mật khẩu mới">
        {getFieldDecorator('password', {
          rules: [
            {
              required: true,
              message: 'Vui lòng điền tên mật khẩu mới'
            }
          ]
        })(<Password size={size} />)}
      </Form.Item>
      <Form.Item>
        <Button
          loading={loading}
          type="primary"
          size={size}
          htmlType="submit"
          disabled={loading}
        >
          Đổi mật khẩu
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Form.create()(withAuth(ChangePassword));
