import React, {
  createContext,
  useReducer,
  type Node,
  useContext,
  useEffect,
  useMemo
} from 'react';
import { Loading } from '../components';
import api from '../api';
import { authContext } from '.';

const borrowerContext = createContext();

const { Provider } = borrowerContext;

const initialState = {
  loading: true
  // loans: []
};

/**
 * borrower context
 */

const types = {
  LOAD_LOANS_DONE: 'borrower/load_loans_done'
};

const borrowerReducer = (state, action) => {
  switch (action.type) {
    case types.LOAD_LOANS_DONE:
      return { ...state, loading: false, loans: action.payload };
    default:
      throw new Error();
  }
};

export const useBorrower = () => {
  const context = useContext(borrowerContext);
  const [state, dispatch] = context;
  return {
    state,
    dispatch
  };
};

const BorrowerContextProvider = ({ children }: { children: Node }) => {
  const [state, dispatch] = useReducer(borrowerReducer, initialState);

  const { user } = useContext(authContext);

  const loadLoansDone = loans =>
    dispatch({ type: types.LOAD_LOANS_DONE, payload: loans });

  useEffect(() => {
    const loadLoans = async () => {
      if (!user) return null;
      const { token } = user;
      const res = await api.loanApi.loans(token);
      return loadLoansDone(res.data.loans);
    };

    loadLoans();
  }, [user]);

  const values = useMemo(() => [state, dispatch], [state]);

  const { loading } = state;
  if (loading) return <Loading />;
  return <Provider value={values}>{children}</Provider>;
};

export { BorrowerContextProvider, borrowerContext as default };
