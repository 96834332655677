// @flow
import React, { memo, useState } from 'react';
import {
  Form,
  InputNumber,
  DatePicker,
  Radio,
  Button,
  Card,
  message
} from 'antd';
import { floor } from 'lodash';
import { Link } from '@reach/router';
import moment from 'moment';

import { withLang, withAuth } from '../../hoc';
import { WrapForm, StyleBox } from './style';
import api from '../../api';
import lang from './lang';
import { generalDataFormLoan } from './ultil';

const FormItem = Form.Item;
const size = 'large';

type LoanProps = {
  form: {
    getFieldDecorator: Function,
    validateFields: Function,
    getFieldsValue: Function,
    setFieldsValue: Function
  },
  cb: Function,
  updateLoan: Function,
  loan: any,
  locale: string,
  user: any,
  isNew: boolean
};

const styleInputNumber = { width: '100%', maxWidth: 320 };

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

function disabledDate(current) {
  // Can not select days before today and today
  // return current && current < moment().endOf('day');
  return current && current < moment().add(-1, 'days');
}

function disabledDateTime() {
  return {
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56]
  };
}

const Loan = ({
  form,
  loan,
  cb,
  updateLoan,
  locale,
  user,
  isNew
}: LoanProps) => {
  const [loading, setLoading] = useState(false);
  const [isRehab, setIsRehab] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();

    const { validateFields } = form;

    validateFields((err, values) => {
      if (!err) {
        const data = generalDataFormLoan(loan, values);

        api.borrowerApi
          .updateLoan({ token: user.token, data })
          .then(res => {
            setLoading(false);
            updateLoan(res.data);
            message.success('Cập nhật thành công khoản vay');
            cb('borrower_profile');
          })
          .catch(() => {
            message.error('Đã xảy ra lỗi, vui lòng cập nhật lại');
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    });
  };

  const handleIsRehab = e => setIsRehab(e.target.value);

  const setValueRatio = ({ total_cost, total_requested_amount }) => {
    const { setFieldsValue } = form;

    if (
      total_cost &&
      typeof total_cost === 'number' &&
      typeof total_requested_amount === 'number' &&
      total_cost !== 0
    ) {
      const valueRatio = floor((total_requested_amount / total_cost) * 100, 2);

      return setFieldsValue({
        loan_to_value_ratio: valueRatio
      });
    }

    return setFieldsValue({ loan_to_value_ratio: 0 });
  };

  const handleRequestedAmount = value => {
    const { getFieldsValue } = form;
    const { total_cost } = getFieldsValue(['total_cost']);
    setValueRatio({ total_cost, total_requested_amount: value });

    form.validateFields(['loan_to_value_ratio'], (errors, values) => {
      console.log('values1', errors, values);
    });
  };

  const handleTotalCost = value => {
    const { getFieldsValue } = form;
    const { total_requested_amount } = getFieldsValue([
      'total_requested_amount'
    ]);

    setValueRatio({ total_cost: value, total_requested_amount });
    form.validateFields(['loan_to_value_ratio'], (errors, values) => {
      console.log('values', errors, values);
    });
  };

  // const validateValueRatio = (rule, value, callback) => {
  //   if (value && value > 30) {
  //     callback('Value ratio max 30');
  //   } else {
  //     callback();
  //   }
  // };
  const { getFieldDecorator } = form;

  return (
    <WrapForm>
      <Card type="inner" title={<div style={{ fontSize: 18 }}>Nguồn vốn</div>}>
        <Form onSubmit={handleSubmit}>
          <h3>1. Thông tin chi tiết về Bất động sản</h3>
          <FormItem label={lang.preferred_close_date[locale]}>
            {getFieldDecorator('preferred_close_date', {
              initialValue:
                loan && loan.preferred_close_date
                  ? moment(loan.preferred_close_date)
                  : undefined,

              rules: [
                {
                  required: true,
                  message: lang.required_preferred_close_date[locale]
                }
              ]
            })(
              <DatePicker
                format="DD-MM-YYYY"
                placeholder="Chọn ngày"
                style={styleInputNumber}
                size={size}
                disabledDate={disabledDate}
                disabledTime={disabledDateTime}
              />
            )}
          </FormItem>

          <FormItem label={lang.property_market_price[locale]}>
            {getFieldDecorator('property_market_price', {
              initialValue: loan ? loan.property_market_price : undefined,
              rules: [
                {
                  required: true,
                  message: lang.required_property_market_price[locale]
                }
              ]
            })(
              <InputNumber
                placeholder="Ex: 100,000,000"
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                style={styleInputNumber}
                size={size}
              />
            )}
          </FormItem>

          <FormItem label={lang.purchase_price[locale]}>
            {getFieldDecorator('purchase_price', {
              initialValue: loan ? loan.purchase_price : undefined,
              rules: [
                {
                  required: true,
                  message: lang.required_purchase_price[locale]
                }
              ]
            })(
              <InputNumber
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                placeholder="Ex: 100,000,000"
                style={styleInputNumber}
                size={size}
              />
            )}
          </FormItem>
          <FormItem label={lang.is_seller_share_costs_documents[locale]}>
            {getFieldDecorator('is_seller_share_costs_documents', {
              initialValue: loan
                ? loan.is_seller_share_costs_documents
                : undefined,
              rules: [
                {
                  required: true,
                  message: lang.required_is_seller_share_costs_documents[locale]
                }
              ]
            })(
              <Radio.Group>
                <Radio value>Có</Radio>
                <Radio value={false}>Không</Radio>
              </Radio.Group>
            )}
          </FormItem>
          <FormItem>
            <div
              style={{
                lineHeight: 1.5,
                color: 'rgba(0, 0, 0, 0.85)',
                marginBottom: 15
              }}
              className="ant-form-item-required"
            >
              {lang.is_new_buyer_pay_additional_cost[locale]}
            </div>

            {getFieldDecorator('is_new_buyer_pay_additional_cost', {
              initialValue: loan
                ? loan.is_new_buyer_pay_additional_cost
                : undefined,
              rules: [
                {
                  required: true,
                  message:
                    lang.required_is_new_buyer_pay_additional_cost[locale]
                }
              ]
            })(
              <Radio.Group>
                <Radio value>Có</Radio>
                <Radio value={false}>Không</Radio>
              </Radio.Group>
            )}
          </FormItem>

          <h3>
            2. Sau khi mua xong bất động sản, bạn có dự định tân trang lại
            không?
          </h3>

          <FormItem>
            {getFieldDecorator('is_rehab_required', {
              initialValue: loan ? loan.is_rehab_required : undefined,
              rules: [
                {
                  required: true,
                  message: lang.required_is_rehab_required[locale]
                }
              ]
            })(
              <Radio.Group onChange={handleIsRehab}>
                <Radio value>Có</Radio>
                <Radio value={false}>Không</Radio>
              </Radio.Group>
            )}
          </FormItem>

          {isRehab && (
            <StyleBox>
              <FormItem label={lang.total_rehad_cost[locale]}>
                {getFieldDecorator('total_rehad_cost', {
                  initialValue: loan ? loan.total_rehad_cost : undefined,
                  rules: [
                    {
                      required: true,
                      message: lang.required_total_rehad_cost[locale]
                    }
                  ]
                })(
                  <InputNumber
                    style={styleInputNumber}
                    size={size}
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                )}
              </FormItem>
              <FormItem label={lang.after_repaired_property_price[locale]}>
                {getFieldDecorator('after_repaired_property_price', {
                  initialValue: loan
                    ? loan.after_repaired_property_price
                    : undefined,
                  rules: [
                    {
                      required: true,
                      message:
                        lang.required_after_repaired_property_price[locale]
                    }
                  ]
                })(
                  <InputNumber
                    style={styleInputNumber}
                    size={size}
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  />
                )}
              </FormItem>
            </StyleBox>
          )}

          <FormItem label={lang.total_requested_amount[locale]}>
            {getFieldDecorator('total_requested_amount', {
              initialValue: loan ? loan.total_requested_amount : undefined,
              rules: [
                {
                  required: true,
                  message: lang.required_total_requested_amount[locale]
                }
              ]
            })(
              <InputNumber
                onChange={handleRequestedAmount}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                style={styleInputNumber}
                size={size}
                placeholder="Ex: 100,000,000"
              />
            )}
          </FormItem>

          <FormItem label={lang.total_cost[locale]}>
            {getFieldDecorator('total_cost', {
              initialValue: loan ? loan.total_cost : undefined,
              rules: [
                {
                  required: true,
                  message: lang.required_total_cost[locale]
                }
              ]
            })(
              <InputNumber
                onChange={handleTotalCost}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                style={styleInputNumber}
                size={size}
                placeholder="Ex: 100,000,000"
              />
            )}
          </FormItem>
          <FormItem label={lang.loan_to_value_ratio[locale]}>
            {getFieldDecorator('loan_to_value_ratio', {
              initialValue: loan ? loan.loan_to_value_ratio : undefined
              // rules: [
              //   {
              //     message: lang.required_loan_to_value_ratio[locale]
              //   }
              // ]
            })(
              <InputNumber
                style={{ ...styleInputNumber, fontWeight: 'bold' }}
                size={size}
                disabled
              />
            )}
          </FormItem>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              {lang.save_continue[locale]}
            </Button>
            {!isNew && (
              <Link to="/borrower/loans">
                <Button style={{ marginLeft: 10 }}>Danh sách khoản vay</Button>
              </Link>
            )}
          </Form.Item>
        </Form>
      </Card>
    </WrapForm>
  );
};

export default memo<LoanProps>(Form.create()(withLang(withAuth(Loan))));
