import React, { type Node } from 'react';

import { ButtonStyle } from './style';

type ButtonProps = {
  children: Node,
  disabled?: boolean
};

const Button = ({ disabled, children, ...rest }: ButtonProps) => {
  return (
    <ButtonStyle disabled={disabled} {...rest}>
      <div className="first">{children}</div>
      <div className="second">{children}</div>
    </ButtonStyle>
  );
};

Button.defaultProps = {
  disabled: false
};

export default Button;
