import styled from 'styled-components';

const LoadingWrap = styled.div`
  background: #fff;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// eslint-disable-next-line
export { LoadingWrap };
