import React, { useContext } from 'react';

import { langContext } from '../context';

const withLang = Component => {
  return props => {
    const { locale } = useContext(langContext);

    return <Component locale={locale} {...props} />;
  };
};

export default withLang;
