import React, { memo } from 'react';

const Dmca = () => {
  return (
    <>
      <a
        href="//www.dmca.com/Protection/Status.aspx?ID=9483d495-f944-4e7f-a074-733b5161d7b3"
        title="DMCA.com Protection Status"
        className="dmca-badge"
        target="_blank"
      >
        <img
          src="https://images.dmca.com/Badges/dmca-badge-w150-2x1-04.png?ID=9483d495-f944-4e7f-a074-733b5161d7b3"
          alt="DMCA.com Protection Status"
          style={{ width: 100 }}
        />
      </a>
      <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"></script>
    </>
  );
};

export default memo(Dmca);
