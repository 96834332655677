import React from 'react';
import { Button } from 'antd';
import { Link } from '@reach/router';

import Layout from '../../layout';
import { theme } from '../../configs';

const Error = () => {
  return (
    <Layout title="404">
      <div
        style={{
          display: 'flex',
          minHeight: '100vh',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <div
            style={{
              fontSize: 80,
              fontWeight: 700,
              marginBottom: 30,
              color: theme.color.status.warning
            }}
          >
            404
          </div>
          <p style={{ fontSize: 20 }}>
            Đã có lỗi xảy ra, vui lòng kiểm tra lại
          </p>
          <Link to="/">
            <Button type="primary" size="large">
              Quay lại
            </Button>
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default Error;
