// @flow
import React, { type Node, useEffect, createContext, useState } from 'react';
import Helmet from 'react-helmet';

import { Header, Loading } from '../components';

type LayoutProps = {
  title?: string,
  header?: boolean,
  children: Node,
  loading?: boolean
};

const initialContext = {
  show_menu: false
};

export const layoutContext = createContext({ ...initialContext });

const { Provider } = layoutContext;

const Layout = ({ title, header, loading, children }: LayoutProps) => {
  const [show_menu, setShowMenu] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading) return <Loading />;

  const style = {
    height: show_menu ? '100vh' : 'auto',
    overflow: show_menu ? 'hidden' : 'auto'
  };

  return (
    <Provider value={{ show_menu, setShowMenu }}>
      <div style={{ ...style }}>
        <Helmet title={title} />
        {header && <Header />}

        <div>{children}</div>
      </div>
    </Provider>
  );
};

Layout.defaultProps = {
  title: '',
  header: true,
  loading: false
};

export default Layout;
