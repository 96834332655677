// @flow
import axios from 'axios';

import { constants } from '../configs';

const URL_API = `${constants.URL}/api/v1`;

export default axios.create({
  baseURL: URL_API,
  'Access-Control-Allow-Origin': '*',
  crossdomain: true
});
