// @flow
import React, { useState, useEffect } from 'react';
import { Icon } from 'antd';
import styled from 'styled-components';
import { theme, constants } from '../../configs';

const Wrap = styled.div`
  display: flex;
  border: 1px dashed ${theme.color.border};
  background: #f5f7f9;
  border-radius: ${theme.size.borderRadius}px;
`;

const ItemWrap = styled.div`
  width: calc(20% - 30px);
  margin: ${theme.size.space}px;
  position: relative;
  border: 1px solid #eee;
  padding: 5px;
  background: #fff;
  border-radius: ${theme.size.borderRadius}px;
  // box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
`;

const ImageStyle = styled.div`
  padding-bottom: 100%;
  background-size: cover;
  background-position: center;
`;

const DeleteStyle = styled.div`
  cursor: pointer;
  color: #fff;
  background: red;
  position: absolute;
  top: -${theme.size.space - 5}px;
  right: -${theme.size.space - 5}px;
  height: ${theme.size.space + 5}px;
  width: ${theme.size.space + 5}px;
  border-radius: 20px;
  text-align: center;
  line-height: ${theme.size.space + 5}px;
`;

const InputFileStyle = styled(ItemWrap)`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;

  input {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
  }
`;

type EditImagesProps = {
  isReset?: any,
  changeReset?: any,
  photos: any,
  photos_add: any,
  removePhoto: Function,
  removePhotoAdd: Function,
  addPhoto: Function
};

const EditImages = ({
  isReset,
  changeReset,
  photos,
  photos_add,
  removePhoto,
  removePhotoAdd,
  addPhoto
}: EditImagesProps) => {
  const [loading, setLoading] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState([]);

  const handleAddPhoto = e => {
    setLoading(true);

    if (e.target.files[0]) {
      addPhoto(e.target.files[0]);

      const reader = new FileReader();
      const file = e.target.files[0];

      reader.onloadend = () => {
        setImagePreviewUrl([...imagePreviewUrl, reader.result]);
        setLoading(false);
      };
      changeReset();
      reader.readAsDataURL(file);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log('reset', isReset);
    if (isReset) {
      setImagePreviewUrl([]);
    }
  }, [isReset]);

  const handleRemovePhotoAdd = (photo, index) => {
    const newImagePreviewUrl = imagePreviewUrl.filter(img => img !== photo);
    setImagePreviewUrl(newImagePreviewUrl);
    removePhotoAdd(index);
  };

  const max_number_photo = photos.length + photos_add.length;

  return (
    <Wrap>
      {photos.map(photo => (
        <ItemWrap key={photo.id}>
          <ImageStyle
            style={{
              backgroundImage: `url(${constants.URL}${photo.url})`
            }}
          />
          <DeleteStyle
            onClick={() => removePhoto(photo.id)}
            role="presentation"
          >
            <Icon type="close" style={{ fontSize: 14 }} />
          </DeleteStyle>
        </ItemWrap>
      ))}

      {imagePreviewUrl.map((photo, index) => (
        <ItemWrap key={photo}>
          <ImageStyle
            style={{
              backgroundImage: `url(${photo})`
            }}
          />
          <DeleteStyle
            onClick={() => handleRemovePhotoAdd(photo, index)}
            role="presentation"
          >
            <Icon type="close" />
          </DeleteStyle>
        </ItemWrap>
      ))}
      {max_number_photo < 5 && (
        <InputFileStyle>
          <input type="file" onChange={handleAddPhoto} />
          <Icon type={loading ? 'loading' : 'plus'} style={{ fontSize: 16 }} />
        </InputFileStyle>
      )}
    </Wrap>
  );
};

export default EditImages;
