// @flow
import React from 'react';
import styled from 'styled-components';
import ImageLoader from 'react-imageloader';
import { Icon } from 'antd';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import './style.css';

import { theme, constants } from '../../configs';
import Loading from '../Loading';

const ArrowStyle = styled.div`
  height: 60px;
  text-align: center;
  line-height: 60px;
  padding: 0 10px;
  background: #090909;
  opacity: 0.5;
  position: absolute;
  top: 50%;
  margin-top: -25px;
  z-index: 999;
  cursor: pointer;
  color: #fff;

  &:hover {
    opacity: 0.9;
  }
`;

type ArrowProps = {
  className: String,
  style: any,
  onClick: Function
};

function NextArrow({ className, style, onClick }: ArrowProps) {
  return (
    <ArrowStyle
      className={className}
      style={{ ...style, right: 0 }}
      onClick={onClick}
      role="presentation"
    >
      <Icon type="right" />
    </ArrowStyle>
  );
}

function PrevArrow({ className, style, onClick }: ArrowProps) {
  return (
    <ArrowStyle
      className={className}
      style={{ ...style, left: 0 }}
      onClick={onClick}
      role="presentation"
    >
      <Icon type="left" />
    </ArrowStyle>
  );
}

const settings = {
  className: '',
  lazyLoad: 'progressive',
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />
};

function preloader() {
  return <Loading />;
}

export default ({ photos }: { photos: Array }) => {
  return (
    <Slider {...settings}>
      {photos.map(photo => (
        <div key={photo.id}>
          <div
            style={{
              background: '#fff',
              border: `1px solid ${theme.color.border}`,
              overflow: 'hidden'
            }}
          >
            <ImageLoader
              src={`${constants.URL}${photo.url}`}
              wrapper={React.createFactory('div')}
              preloader={preloader}
            >
              Image load failed!
            </ImageLoader>
          </div>
        </div>
      ))}
    </Slider>
  );
};
