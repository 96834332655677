import lang from './lang';

export default locale => ({
  links: [
    {
      path: '/',
      title: lang.footer1.links.link1[locale]
    },
    {
      path: '/about-us',
      title: lang.footer1.links.link2[locale]
    },
    {
      path: '/faqs',
      title: lang.footer1.links.link3[locale]
    },

    {
      path: '/',
      title: lang.footer1.links.link4[locale]
    }
  ],
  links1: [
    {
      path: 'https://www.idautu.com',
      title: lang.footer2.links.link1[locale],
      type: 'a'
    },
    {
      path: 'https://idautu.com/category/tai-chinh-ca-nhan',
      title: lang.footer2.links.link2[locale],
      type: 'a'
    },
    {
      path: 'https://www.finrei.com/dieu-khoan-su-dung-dich-vu-finrei.pdf',
      title: lang.footer2.links.link3[locale],
      download: true,
      type: 'a'
    },

    {
      path: 'https://www.finrei.com/dieu-khoan-bao-mat-finREI.pdf',
      title: lang.footer2.links.link4[locale],
      download: true,
      type: 'a'
    }
  ],
  information: [
    {
      path: '/',
      title: 'Công ty cổ phần đầu tư FinRei'
    },
    {
      path: '/',
      title: 'Địa chỉ: 31 Hàn Thuyên, Phường Bến Nghé, Quận 1, TP.HCM, Việt Nam'
    },
    {
      path: '/',
      title: 'Email: info@finrei.com'
    }
  ]
});
