import axios from './axios';

export default {
  signup: data =>
    axios({
      method: 'POST',
      url: '/auth/sign_up',
      data,
      headers: { 'Content-Type': 'application/json' }
    }),

  confirmations: confirmations_token =>
    axios({
      method: 'PUT',
      url: `/confirmations/${confirmations_token}`,
      data: {},
      headers: { 'Content-Type': 'application/json' }
    }),

  re_confirmations: data =>
    axios({
      method: 'POST',
      url: '/confirmations',
      data,
      headers: { 'Content-Type': 'application/json' }
    }),

  login: data =>
    axios({
      method: 'POST',
      url: '/auth/login',
      data
    }),
  forgotPassword: data =>
    axios({
      method: 'POST',
      url: '/password/forgot',
      data
    }),
  resetPassword: data =>
    axios({
      method: 'POST',
      url: '/password/reset',
      data
    }),
  changePassword: (token, data) =>
    axios({
      method: 'PUT',
      url: '/password/update',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data
    }),
  user: ({ token, userId }) =>
    axios({
      method: 'GET',
      url: `/users/${userId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*'
      }
    }),
  logout: token =>
    axios({
      method: 'DELETE',
      url: '/auth/logout',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*'
      }
    })
};
