// @flow
import React, { memo } from 'react';
import { Icon } from 'antd';

type TabHeadingProps = {
  title: String,
  active?: boolean
};

const color = {
  normal: '#fff',
  active: 'green'
};

const style = {
  marginLeft: 15,
  color
};

const TabHeading = ({ title, active }: TabHeadingProps) => {
  return (
    <span>
      <b>{title}</b>
      <Icon
        type="check-circle"
        theme={active ? 'filled' : 'outlined'}
        style={{ ...style, color: active ? 'green' : 'inherit' }}
      />
    </span>
  );
};

TabHeading.defaultProps = { active: false };

export default memo<TabHeadingProps>(TabHeading);
