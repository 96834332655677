import React from 'react';
import { Link } from '@reach/router';

import { langContext } from '../../context';
import Button from '../Button';
import lang from './lang';

const ButtonLogin = () => {
  const { locale } = React.useContext(langContext);
  return (
    <Link to="/signin">
      <Button>{lang.btn_login[locale]}</Button>
    </Link>
  );
};

export default ButtonLogin;
