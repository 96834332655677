import authApi from './authApi';
import masterApi from './masterApi';
import borrowerApi from './borrowerApi';
import loanApi from './loanApi';
import investorApi from './investorApi';

export default {
  authApi,
  masterApi,
  borrowerApi,
  loanApi,
  investorApi
};
