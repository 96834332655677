// @flow
import React from 'react';
import { Card } from 'antd';

import { withLang } from '../../hoc';
import Layout from '../../layout';
import ProfileForm from './ProfileForm';
import lang from './lang';
import ChangePassword from './ChangePassword';
import { theme } from '../../configs';

type BorrowerProfileProps = { locale: string };

const BorrowerProfile = ({ locale }: BorrowerProfileProps) => {
  return (
    <Layout title={lang.title[locale]} header={false}>
      <Card
        style={{ marginBottom: theme.size.space * 2 }}
        title={<span style={{ fontSize: 18 }}>{lang.title[locale]}</span>}
        type="inner"
      >
        <ProfileForm />
      </Card>

      <Card
        title={<span style={{ fontSize: 18 }}>Đổi mật khẩu</span>}
        type="inner"
      >
        <ChangePassword />
      </Card>
    </Layout>
  );
};

export default withLang(BorrowerProfile);
