import React, { useContext } from 'react';
import { Link } from '@reach/router';

import { withLang } from '../../hoc';
import { navigate } from '../../history';
import { authContext } from '../../context';
import { layoutContext } from '../../layout';
import Logo from './Logo';
import {
  IconMenuStyle,
  MenuMobileStyle,
  SectionMenuMobileStyle,
  MenuItemMobileStyle
} from './style';
import { menus } from './Nav';
import Button from '../Button';
import SelectLang from './SelectLang';

const menu_borrower = [
  {
    to: '/borrower/profile',
    title: 'Trang cá nhân'
  },
  {
    to: '/borrower/loans',
    title: 'Các khoản vay'
  },
  {
    to: '/loan/new',
    title: 'Khoản vay mới'
  }
];

const menu_investor = [
  { to: '/investor/dashboard', title: 'Quản lý tài khoản' },
  { to: '/investor/profile', title: 'Trang cá nhân' },
  { to: '/investor/dau-tu', title: 'Đầu tư' },
  { to: '/investor/add-fund', title: 'Nạp tiền' }
];

const HeaderSmall = ({ locale }) => {
  const { user, logout } = useContext(authContext);
  const { show_menu, setShowMenu } = useContext(layoutContext);

  const toggleShow = () => setShowMenu(!show_menu);

  const redirectTo = path => {
    navigate(path);
    toggleShow();
  };

  const renderMenu = () => {
    if (user && user.role === 'investor') {
      return (
        <div>
          {menu_investor.map(menu => (
            <MenuItemMobileStyle
              to={menu.to}
              key={menu.to}
              onClick={() => redirectTo(menu.to)}
            >
              {menu.title}
            </MenuItemMobileStyle>
          ))}
          <MenuItemMobileStyle onClick={() => logout()} key="x">
            Đăng xuất
          </MenuItemMobileStyle>
        </div>
      );
    }

    if (user && user.role === 'borrower') {
      return (
        <div>
          {menu_borrower.map(menu => (
            <MenuItemMobileStyle
              to={menu.to}
              key={menu.to}
              onClick={() => redirectTo(menu.to)}
            >
              {menu.title}
            </MenuItemMobileStyle>
          ))}
          <MenuItemMobileStyle onClick={() => logout()}>
            Đăng xuất
          </MenuItemMobileStyle>
        </div>
      );
    }

    return (
      <Link to="/signin">
        <Button>Đăng nhập</Button>
      </Link>
    );
  };

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <Logo width={80} />

      <div>
        <IconMenuStyle show={show_menu} onClick={toggleShow}>
          <span />
        </IconMenuStyle>
      </div>

      {show_menu && (
        <MenuMobileStyle>
          <SectionMenuMobileStyle>
            {menus(locale).map(menu => (
              <MenuItemMobileStyle
                to={menu.to}
                key={menu.to}
                onClick={() => redirectTo(menu.to)}
              >
                {menu.title}
              </MenuItemMobileStyle>
            ))}

            <MenuItemMobileStyle>
              <SelectLang />
            </MenuItemMobileStyle>
          </SectionMenuMobileStyle>

          <SectionMenuMobileStyle>{renderMenu()}</SectionMenuMobileStyle>
        </MenuMobileStyle>
      )}
    </div>
  );
};

export default withLang(HeaderSmall);
