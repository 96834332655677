import axios from './axios';

/**
 *  BORROWER
 * 1. creatLoanProperty
 * 2. updateLoanProperty
 * 3. updateLoan
 * 4. updateBorrowerProfile
 * 5. updateBorrowerGuarantor
 * 6. updateBorrowerGuarantor
 * 7. updateBorrowerExperience
 */

export default {
  creatLoanProperty: ({ token, data }) =>
    axios({
      method: 'POST',
      url: `/borrowers/properties`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
        // 'Content-Type': 'application/json'
      },
      data
    }),
  updateLoanProperty: ({ token, data, idProperty }) => {
    return axios({
      method: 'PUT',
      url: `/borrowers/properties/${idProperty}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
        // 'Content-Type': 'application/json'
      },
      data
    });
  },
  updateLoan: ({ token, data }) =>
    axios({
      method: 'POST',
      url: `/borrowers/loans`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data
    }),
  updateBorrowerProfile: ({ token, data }) =>
    axios({
      method: 'POST',
      url: `/borrowers/profiles`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data
    }),
  updateBorrowerGuarantor: ({ token, data }) =>
    axios({
      method: 'POST',
      url: `/borrowers/guarantor_profiles`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data
    }),

  updateBorrowerExperience: ({ token, data }) =>
    axios({
      method: 'POST',
      url: `/borrowers/experiences`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data
    })
};
