// @flow
import React, { useState, memo } from 'react';
import { Tabs } from 'antd';

import { withLang } from '../../hoc';
import tabs from './tabs';

const { TabPane } = Tabs;
const defaultActiveKey = 'property';
const tabBarStyle = { textAlign: 'center', background: '#fff' };

type StepLoanProps = { loan: any, isNew?: boolean, locale: string };

const StepLoan = ({ locale, loan, isNew }: StepLoanProps) => {
  const [startLoan, setLoan] = useState(loan);
  const [activeKey, setActiveKey] = useState(defaultActiveKey);

  function callback(key) {
    setActiveKey(key);
  }

  const updateLoan = newLoan => setLoan({ ...startLoan, ...newLoan });

  const renderTabs = () =>
    tabs({ cb: callback, locale, loan: startLoan, updateLoan, isNew }).map(
      t => (
        <TabPane tab={t.title} key={t.tab} disabled={!loan && t.key !== 1}>
          {t.component}
        </TabPane>
      )
    );

  return (
    <Tabs
      activeKey={`${activeKey}`}
      onChange={callback}
      tabBarStyle={tabBarStyle}
      animated={{
        inkBar: true,
        tabPane: false
      }}
    >
      {renderTabs()}
    </Tabs>
  );
};

StepLoan.defaultProps = {
  isNew: false
};

export default memo<StepLoanProps>(withLang(StepLoan));
