import React from 'react';

import { NavStyle, NavLinkStyle } from './style';
import { langContext } from '../../context';
import lang from './lang';

export const menus = location => [
  {
    to: '/',
    title: lang.menus.homepage[location]
  },
  {
    to: '/loan/new',
    title: lang.menus.fund_seekers[location]
  },

  {
    to: '/investor/dau-tu',
    title: lang.menus.investors[location]
  },
  {
    to: '/about-us',
    title: lang.menus.about_finrei[location]
  }
];

const NavLinkProps = {
  menu: {
    path: String,
    title: String
  }
};

const NavLink = ({ menu }: NavLinkProps) => {
  return (
    <NavLinkStyle to={menu.to} style={{ marginRight: 30 }}>
      {menu.title}
    </NavLinkStyle>
  );
};

const Nav = () => {
  const { locale } = React.useContext(langContext);
  return (
    <NavStyle>
      {menus(locale).map(menu => (
        <NavLink key={menu.title} menu={menu} />
      ))}
    </NavStyle>
  );
};

export default Nav;
