import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap&subset=latin-ext,vietnamese');

  * {
    box-sizing: border-box;
  }
  body {
    color: #333;
  }
  // body {
    
  //   // font-family: -apple-system, 'Open Sans', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   //   sans-serif;
  //   -webkit-font-smoothing: antialiased;
  //   -moz-osx-font-smoothing: grayscale;
  //   font-weight: 400;
  //   // background: #f9f9f9;
  // }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  .label_form {
    font-weight: 600
  }

  .image-upload-custom .fileContainer .uploadPictureContainer{
    width: calc(20% - 30px);
    padding: 5px;
    margin: 15px;
  }

  .image-upload-custom .fileContainer .deleteImage {
    position: absolute;
    top: -15px;
    right: -15px;
    color: #fff;
    background: red;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    line-height: 30px;
    width: 30px;
    height: 30px;
  }

  @media(max-width: 991px) {
    .image-upload-custom .fileContainer .uploadPictureContainer{
      width: calc(33.333% - 30px);
      
    } 
  }

  @media(max-width: 767px) {
    .image-upload-custom .fileContainer .uploadPictureContainer{
      width: calc(100% - 30px)
    } 
  }

`;

export default GlobalStyle;
