import React, { useState, useEffect, memo } from 'react';
import { Form, Radio, Button, Select, Modal, Card } from 'antd';
import { Link } from '@reach/router';

import { navigate } from '../../history';
import api from '../../api';
import { withLang, withAuth } from '../../hoc';
import { WrapForm, StyleBox } from './style';
import lang from './lang';
import { useInvestmentTypes, useExperienceYears } from './useHooks';

const size = 'large';
const FormItem = Form.Item;
const { Option } = Select;
const { success } = Modal;

type GuarantorProps = {
  form: {
    getFieldDecorator: Function,
    validateFields: Function,
    getFieldsValue: Function,
    setFieldsValue: Function
  },
  // cb: Function,
  updateLoan: Function,
  loan: any,
  user: any,
  locale: string,
  isNew: boolean
};

const Experience = ({
  form,
  loan,
  updateLoan,
  locale,
  user,
  isNew
}: GuarantorProps) => {
  const [loading, setLoading] = useState(false);
  const [investmentTypes] = useInvestmentTypes();
  const [experienceYears] = useExperienceYears();
  const [isRealstate, setIsRealstate] = useState(false);

  const showConfirm = () => {
    success({
      title: <b>Hoàn tất</b>,
      content: 'Khoản vay đã được cập nhật thành công',
      okText: 'Danh sách khoản vay',
      cancelText: '',
      onOk() {
        navigate(`/borrower/loans/`);
      },
      onCancel() {}
    });
  };

  const initialValue = {
    has_real_estate_investment: null,
    experience: {
      investment_master_id: null,
      investment_experience_years_master_id: null
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    const { validateFields } = form;

    validateFields((err, values) => {
      if (!err) {
        const { has_real_estate_investment, ...rest } = values;

        const data = {
          ...initialValue,
          loan_id: loan.id,
          has_real_estate_investment,
          experience: {
            ...initialValue.experience,
            ...rest
          }
        };

        api.borrowerApi
          .updateBorrowerExperience({ token: user.token, data })
          .then(res => {
            updateLoan(res.data);
            setLoading(false);
            showConfirm();
          })
          .catch(() => {
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (loan && loan.has_real_estate_investment) {
      setIsRealstate(!!loan.has_real_estate_investment);
    }
  }, [loan]);

  const handleIsRealstate = e => setIsRealstate(e.target.value);

  const { getFieldDecorator } = form;

  return (
    <WrapForm>
      <Card
        type="inner"
        title={<div style={{ fontSize: 18 }}>Kinh nghiệm đầu tư</div>}
      >
        <Form onSubmit={handleSubmit}>
          <h3>{lang.has_real_estate_investment[locale]}</h3>

          <FormItem>
            {getFieldDecorator('has_real_estate_investment', {
              initialValue: loan && loan.has_real_estate_investment,
              rules: [
                {
                  required: true,
                  message: lang.required_has_real_estate_investment[locale]
                }
              ]
            })(
              <Radio.Group onChange={handleIsRealstate}>
                <Radio value>
                  {lang.label_has_real_estate_investment[locale]}
                </Radio>
                <Radio value={false}>
                  {lang.label_has_not_real_estate_investment[locale]}
                </Radio>
              </Radio.Group>
            )}
          </FormItem>

          {isRealstate && (
            <StyleBox style={{ marginBottom: 32 }}>
              <FormItem label={lang.label_has_real_estate_investment[locale]}>
                {getFieldDecorator('investment_master_id', {
                  initialValue:
                    loan && loan.experience
                      ? loan.experience.investment_type_data.id
                      : undefined,
                  rules: [
                    {
                      required: true,
                      message:
                        lang.required_label_has_real_estate_investment[locale]
                    }
                  ]
                })(
                  <Select
                    size={size}
                    placeholder={
                      lang.required_label_has_real_estate_investment[locale]
                    }
                  >
                    {investmentTypes.map(r => (
                      <Option value={r.id} key={r.id}>
                        {r.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>

              <FormItem
                label={lang.investment_experience_years_master_id[locale]}
              >
                {getFieldDecorator('investment_experience_years_master_id', {
                  initialValue:
                    loan && loan.experience
                      ? loan.experience.investment_experience_data.id
                      : undefined,
                  rules: [
                    {
                      required: true,
                      message:
                        lang.required_investment_experience_years_master_id[
                          locale
                        ]
                    }
                  ]
                })(
                  <Select
                    size={size}
                    placeholder={
                      lang.investment_experience_years_master_id[locale]
                    }
                  >
                    {experienceYears.map(r => (
                      <Option value={r.id} key={r.id}>
                        {r.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </StyleBox>
          )}

          <h3>Bạn đã từng Đầu tư Chứng khoán và Trái phiếu</h3>

          <FormItem>
            {getFieldDecorator('has_stock_investment', {
              initialValue: loan && loan.has_stock_investment,
              rules: [
                {
                  required: true,
                  message: lang.required_has_real_estate_investment[locale]
                }
              ]
            })(
              <Radio.Group>
                <Radio value>Đã từng Đầu tư</Radio>
                <Radio value={false}>Chưa từng Đầu tư</Radio>
              </Radio.Group>
            )}
          </FormItem>

          <h3>Bạn đã từng Đầu tư Vàng và Tiền ảo</h3>

          <FormItem>
            {getFieldDecorator('has_crypto_or_gold_investment', {
              initialValue: loan && loan.has_crypto_or_gold_investment,
              rules: [
                {
                  required: true,
                  message: lang.required_has_real_estate_investment[locale]
                }
              ]
            })(
              <Radio.Group>
                <Radio value>Đã từng Đầu tư</Radio>
                <Radio value={false}>Chưa từng Đầu tư</Radio>
              </Radio.Group>
            )}
          </FormItem>

          <h3>Bạn đã từng Có Công ty của riêng mình</h3>

          <FormItem>
            {getFieldDecorator('has_company_owner', {
              initialValue: loan && loan.has_company_owner,
              rules: [
                {
                  required: true,
                  message: lang.required_has_real_estate_investment[locale]
                }
              ]
            })(
              <Radio.Group>
                <Radio value>Có</Radio>
                <Radio value={false}>Không</Radio>
              </Radio.Group>
            )}
          </FormItem>

          <FormItem>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={loading}
            >
              {lang.save_continue[locale]}
            </Button>
            {!isNew && (
              <Link to="/borrower/loans">
                <Button style={{ marginLeft: 10 }}>Danh sách khoản vay</Button>
              </Link>
            )}
          </FormItem>
        </Form>
      </Card>
    </WrapForm>
  );
};

export default memo(Form.create()(withLang(withAuth(Experience))));
