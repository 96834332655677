import React, { type Node } from 'react';
import { AuthContextProvider, LangContextProvider } from '.';

const Store = ({ children }: { children: Node }) => {
  return (
    <AuthContextProvider>
      <LangContextProvider>{children}</LangContextProvider>
    </AuthContextProvider>
  );
};

export default Store;
