import axios from './axios';

/**
 * API INVESTOR
 * 1. first_investment_progress
 * 2. update_investor_profile
 * 3. economic_conditions
 * 4. document
 * 5. document_update
 * 6. agreements
 * 7. overview_account
 * 8. list_loans
 * 9. investment
 * 10. get_list_investments
 * 11. investment_loan
 * 12. automated_investings
 * 13. add_fund
 * 14. history
 */

export default {
  investment_progress: token =>
    axios({
      method: 'GET',
      url: `/investors/investment_progress`,
      // url: `/investors/first_investment_progress`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }),

  update_investor_profile: (token, data) =>
    axios({
      method: 'POST',
      url: `/investors/update_profile`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data
    }),

  economic_conditions: (token, data) =>
    axios({
      method: 'POST',
      url: `/investors/economic_conditions`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data
    }),
  // document upload
  document: (token, data) =>
    axios({
      method: 'POST',
      url: `/investors/documents`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      },
      data
    }),
  // document update
  document_update: ({ token, data, id }) =>
    axios({
      method: 'PUT',
      url: `/investors/documents/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      },
      data
    }),
  // agreements
  agreements: (token, data) =>
    axios({
      method: 'POST',
      url: `/investors/agreements`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data
    }),

  // overview account
  overview_account: token =>
    axios({
      method: 'GET',
      url: `/investors/account_detail`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }),

  // get list loan for investor
  list_loans: ({ token, filter, page, per_page }) =>
    axios({
      method: 'GET',
      url: `/investors/loans`,
      params: {
        filter,
        page,
        per_page: per_page || 15
      },

      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }),

  // investor invest a loan
  investment: (token, data) =>
    axios({
      method: 'POST',
      url: `/investors/investments`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data
    }),

  // get list investment of investor
  get_list_investments: token =>
    axios({
      method: 'GET',
      url: `/investors/investments`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }),

  // get detail loan for investor
  investment_loan: (token, idLoan) =>
    axios({
      method: 'GET',
      url: `/investors/loans/${idLoan}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }),

  // investor invest a loan
  automated_investings: (token, data) =>
    axios({
      method: 'POST',
      url: `/investors/automated_investings`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data
    }),
  // add fund
  add_fund: ({ token, data }) =>
    axios({
      method: 'POST',
      url: `/investors/funds`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data
    }),
  // histories
  histories: token =>
    axios({
      method: 'GET',
      url: '/investors/histories',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
};
