import { toNumber, pick, forEach } from 'lodash';
import moment from 'moment';

const removeAlias = (alias: string) => {
  let str = alias;
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(
    // eslint-disable-next-line
    /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    ' '
  );
  str = str.replace(/ + /g, ' ');
  str = str.trim();
  return str;
};

const generalDataFormProperty = values => {
  const intended_to_require_debt_date = moment(
    values.intended_to_require_debt_date
  ).format('YYYY-MM-DD');
  return {
    property: {
      street_address: values.street_address,
      district_master_id: toNumber(values.district_master_id),
      province_master_id: toNumber(values.province_master_id),
      unit_type_master_id: toNumber(values.unit_type_master_id)
    },
    loan: {
      loan_purpose_master_id: toNumber(values.loan_purpose_master_id),
      intended_to_require_debt_date,
      ...pick(values, [
        'is_signed_on_real_estate_contract',
        'cost_of_improvement',
        'live_in_investing_property',
        'have_interior_information'
      ])
    }
  };
};

const generalFormDataProperty = values => {
  const formData = new FormData();

  formData.append('property[street_address]', values.street_address);
  formData.append('property[district_master_id]', values.district_master_id);
  formData.append('property[province_master_id]', values.province_master_id);
  formData.append('property[unit_type_master_id]', values.unit_type_master_id);

  forEach(values.photos, photo => formData.append('property[photos][]', photo));
  forEach(values.photos_add, photo =>
    formData.append('property[photos][]', photo)
  );

  forEach(values.delete_image_ids, id =>
    formData.append('property[delete_image_ids][]', id)
  );

  const intended_to_require_debt_date = moment(
    values.intended_to_require_debt_date
  ).format('YYYY-MM-DD');

  formData.append(
    'loan[loan_purpose_master_id]',
    values.loan_purpose_master_id
  );
  formData.append(
    'loan[intended_to_require_debt_date]',
    intended_to_require_debt_date
  );
  formData.append(
    'loan[is_signed_on_real_estate_contract]',
    values.is_signed_on_real_estate_contract
  );
  formData.append('loan[cost_of_improvement]', values.cost_of_improvement);
  formData.append(
    'loan[live_in_investing_property]',
    values.live_in_investing_property
  );
  formData.append(
    'loan[have_interior_information]',
    values.have_interior_information
  );

  return formData;
};

const generalDataFormLoan = (loan, values) => {
  const preferred_close_date = moment(values.preferred_close_date).format(
    'YYYY-MM-DD'
  );

  return {
    loan_id: loan.id,
    loan: {
      ...values,
      preferred_close_date,
      loan_purpose_master_id: loan.loan_purpose_data.id,
      ...pick(loan, [
        'is_signed_on_real_estate_contract',
        'intended_to_require_debt_date',
        'cost_of_improvement',
        'live_in_investing_property',
        'have_interior_information'
      ])
    }
  };
};

export {
  generalDataFormProperty,
  generalFormDataProperty,
  generalDataFormLoan,
  removeAlias
};
