import { useState, useEffect } from 'react';

import api from '../../api';

const useRelationship = () => {
  const [relationships, setRelationships] = useState([]);

  useEffect(() => {
    api.masterApi
      .relationship()
      .then(res => {
        setRelationships(res.data);
      })
      .catch(() => setRelationships([]));
  }, []);

  return [relationships];
};

const useOccupations = () => {
  const [occupations, setOccupations] = useState([]);

  useEffect(() => {
    api.masterApi
      .occupations()
      .then(res => {
        setOccupations(res.data);
      })
      .catch(() => setOccupations([]));
  }, []);

  return [occupations];
};

const useInvestmentTypes = () => {
  const [investmentTypes, setInvestmentTypes] = useState([]);

  useEffect(() => {
    api.masterApi
      .investment_types()
      .then(res => {
        setInvestmentTypes(res.data);
      })
      .catch(() => setInvestmentTypes([]));
  }, []);

  return [investmentTypes];
};

const useExperienceYears = () => {
  const [experienceYears, setExperienceYears] = useState([]);

  useEffect(() => {
    api.masterApi
      .investment_experience_years()
      .then(res => {
        setExperienceYears(res.data);
      })
      .catch(() => setExperienceYears([]));
  }, []);

  return [experienceYears];
};

const useBanks = () => {
  const [banks, setBanks] = useState([]);
  useEffect(() => {
    api.masterApi
      .banks()
      .then(res => {
        setBanks(res.data);
      })
      .catch(() => {
        setBanks([]);
      });
  }, []);

  return banks;
};

const useEntityTypes = () => {
  const [entity_types, setEntityTypes] = useState([]);

  useEffect(() => {
    api.masterApi
      .entity_types()
      .then(res => setEntityTypes(res.data))
      .catch(() => setEntityTypes([]));
  }, []);

  return entity_types;
};

export {
  useRelationship,
  useOccupations,
  useInvestmentTypes,
  useExperienceYears,
  useBanks,
  useEntityTypes
};
