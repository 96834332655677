import React from 'react';
import { Dropdown, Menu, Icon } from 'antd';

import { SelectLangTitle } from './style';
import { langContext } from '../../context';

const langs = {
  vi: {
    id: 'vi',
    title: 'Tiếng Việt'
  },
  en: {
    id: 'en',
    title: 'English'
  }
};

const SelectLang = () => {
  const { locale, handleLocale } = React.useContext(langContext);
  console.log(locale);

  const selectLang = key => () => {
    console.log(key, locale);
    handleLocale(key);
  };

  const langMenu = (
    <Menu>
      {Object.keys(langs).map(key => {
        console.log(key);
        return (
          <Menu.Item key={key} onClick={selectLang(key)}>
            {langs[key].title}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <Dropdown overlay={langMenu} trigger={['click']}>
      <SelectLangTitle>
        {langs[locale].title} <Icon type="down" />
      </SelectLangTitle>
    </Dropdown>
  );
};

export default SelectLang;
