// @flow
import React, {
  createContext,
  type Node,
  useReducer,
  useMemo,
  useContext,
  useEffect,
  useCallback
} from 'react';

// import { useInvestmentsInvestor } from './useHooks';
import { withAuth } from '../hoc';
import { authContext } from '.';
import api from '../api';

const investorContext = createContext();

const { Provider } = investorContext;

const initialState = {
  loading: true,
  account: null,
  lendings: [],
  investments: [],
  histories: []
};

const investorReducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_DONE':
      return {
        ...state,
        ...action.payload,
        loading: false
      };

    case 'ADD_INVESTMENT':
      return {
        ...state,
        investments: [...state.investments, action.payload]
      };

    case 'ADD_FUND':
      return {
        ...state,
        account: action.payload
      };
    default:
      return state;
  }
};

export const useInvestor = () => {
  const context = useContext(investorContext);
  const { user, removeAuth } = useContext(authContext);

  const [state, dispatch] = context;

  const loadInitDone = useCallback(
    payload => dispatch({ type: 'LOAD_DONE', payload }),
    [dispatch]
  );

  const addInvestment = investment =>
    dispatch({ type: 'ADD_INVESTMENT', payload: investment });

  const addFund = account => dispatch({ type: 'ADD_FUND', payload: account });

  useEffect(() => {
    const handleLoadInit = async () => {
      if (!user) return loadInitDone({ account: null });

      const { token } = user;

      try {
        const resAccount = await api.investorApi.overview_account(token);
        const resInvestments = await api.investorApi.get_list_investments(
          token
        );
        const resHistories = await api.investorApi.histories(token);

        const resLending = await api.masterApi.lending_pages();

        return loadInitDone({
          account: resAccount.data,
          investments: resInvestments.data,
          lendings: resLending.data,
          histories: resHistories.data
        });
      } catch (error) {
        removeAuth();
        return loadInitDone({ account: null });
      }
    };

    handleLoadInit();
  }, [loadInitDone, removeAuth, user]);

  return { state, dispatch, addInvestment, addFund };
};

const InvestorContextProvider = withAuth(({ children }: { children: Node }) => {
  const [state, dispatch] = useReducer(investorReducer, initialState);

  const value = useMemo(() => [state, dispatch], [state]);
  return <Provider value={value}>{children}</Provider>;
});

export { InvestorContextProvider, investorContext as default };
