// @flow
import React, { memo } from 'react';
import { map, isNull } from 'lodash';
import moment from 'moment';
import {
  Card,
  Form,
  Input,
  Button,
  Radio,
  Select,
  Row,
  Col,
  DatePicker,
  InputNumber,
  message
} from 'antd';
import { Link } from '@reach/router';
import ImageUploader from 'react-images-upload';

import { navigate } from '../../history';
import { withLang, withAuth } from '../../hoc';
import api from '../../api';
import { WrapForm, StyleBox } from './style';
import lang from './lang';
import { generalFormDataProperty, removeAlias } from './ultil';
import { theme } from '../../configs';
import EditImages from './EditImages';

const { Option } = Select;
const { TextArea } = Input;
const size = 'large';

type PropertyProps = {
  form: {
    getFieldDecorator: Function,
    validateFields: Function
  },
  isNew: boolean,
  cb: Function,
  updateLoan: Function,
  loan: any,
  locale: string,
  user: any
};

type PropertyState = {
  loading: boolean,
  provinces: any,
  districts: any,
  loanPurposes: any,
  propertyUnitTypes: any,
  purpose_of_loan: any,
  idProvince: any,
  photos: any,
  errorPhoto: any
};

const messageText = {
  success: 'Tạo bất động sản thành công',
  error: 'Xảy ra lỗi, vui lòng kiểm tra lại.'
};

class Property extends React.Component<PropertyProps, PropertyState> {
  state = {
    loading: false,
    isReset: false,
    provinces: [],
    idProvince: null,
    districts: [],
    propertyUnitTypes: [],
    loanPurposes: [],
    purpose_of_loan: null,
    // photo: null,
    photos: [],
    delete_image_ids: [],
    photos_add: [],
    errorPhoto: null
  };

  componentDidMount() {
    this.setState({ isReset: false });
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { idProvince } = this.state;
    if (!idProvince) return null;
    if (prevState.idProvince !== idProvince) {
      return this.loadProvinces(idProvince);
    }

    return null;
  }

  // eslint-disable-next-line no-unused-vars
  onDrop = (pictureFiles, pictureDataURLs) => {
    this.setState({
      photos: pictureFiles,
      errorPhoto: pictureFiles.length > 5 ? 'Chỉ được upload 5 hình ảnh' : null
    });
  };

  init = async () => {
    const { loan } = this.props;

    await this.loadProvinces();
    if (loan) {
      const { property, loan_purpose_data } = loan;
      await this.loadDistricts(property.province_data.id);
      const purpose_of_loan = loan_purpose_data ? loan_purpose_data.id : null;
      this.setState({ purpose_of_loan, photos: loan.property.photos });
    }
    await this.loadPropertyUnitTypes();
    await this.loadPurposes();
  };

  // loadProvinces
  loadProvinces = async () => {
    api.masterApi
      .provinces()
      .then(res => {
        this.setState({
          provinces: res.data
        });
      })
      .catch(() => {
        this.setState({ provinces: [] });
      });
  };

  // load districts
  loadDistricts = idProvinces => {
    api.masterApi
      .districts(idProvinces)
      .then(res => {
        this.setState({
          districts: res.data
        });
      })
      .catch(() => {
        this.setState({ districts: [] });
      });
  };

  // load propertyUnitTypes
  loadPropertyUnitTypes = () => {
    api.masterApi.propertyUnitTypes().then(res => {
      this.setState({ propertyUnitTypes: res.data });
    });
  };

  // load loan Purposes
  loadPurposes = () => {
    api.masterApi.loanPurposes().then(res => {
      this.setState({ loanPurposes: res.data });
    });
  };

  // remove photo
  removePhoto = idPhoto => {
    this.setState(prevState => {
      const filterPhotos = prevState.photos.filter(
        photo => photo.id !== idPhoto
      );

      return {
        photos: filterPhotos,
        delete_image_ids: [...prevState.delete_image_ids, idPhoto]
      };
    });
  };

  // add photo
  addPhoto = file => {
    this.setState(prevState => ({
      photos_add: [...prevState.photos_add, file]
    }));
  };

  // remove photo add
  removePhotoAdd = index => {
    this.setState(prevState => {
      const filterPhotoAdd = prevState.photos_add.filter((_, i) => i !== index);

      return { photos_add: filterPhotoAdd };
    });
  };

  handleSubmit = elm => {
    elm.preventDefault();
    const { form, cb, updateLoan, isNew, user, loan } = this.props;
    const { photos, errorPhoto, delete_image_ids, photos_add } = this.state;
    const { validateFields } = form;

    this.setState({ loading: true }, () => {
      validateFields((err, values) => {
        if (!err && !errorPhoto) {
          if (user) {
            const { token } = user;

            if (isNew) {
              if (isNull(photos) || photos.length === 0) {
                this.setState({
                  errorPhoto: 'Vui lòng upload hình ảnh',
                  loading: false
                });
              } else {
                const data = generalFormDataProperty({
                  ...values,
                  photos
                });

                api.borrowerApi
                  .creatLoanProperty({ data, token })
                  .then(res => {
                    const newLoan = res.data;
                    updateLoan(newLoan);

                    message.success(messageText.success);
                    this.setState({ loading: false, errorPhoto: null });
                    // cb(2)
                    navigate(`/loan/edit/${newLoan.id}`);
                  })
                  .catch(() => {
                    message.error(messageText.error);
                    this.setState({ loading: false, errorPhoto: null });
                  });
              }
            } else {
              console.log('photos_add.length', photos_add, photos);

              if (photos.length === 0 && photos_add.length === 0) {
                this.setState({
                  errorPhoto: 'Vui lòng upload hình ảnh',
                  loading: false
                });
              } else {
                const data = generalFormDataProperty({
                  ...values,
                  photos_add,
                  delete_image_ids
                });

                api.borrowerApi
                  .updateLoanProperty({
                    data,
                    token,
                    idProperty: loan.property.id
                  })
                  .then(res => {
                    const newLoan = res.data;
                    updateLoan({ ...newLoan, isNew: false });

                    message.success('Cập nhật Thành công');

                    this.setState({
                      loading: false,
                      errorPhoto: null,
                      photos: newLoan.property.photos,
                      photos_add: [],
                      isReset: true
                    });
                    cb('loan');
                  })
                  .catch(() => {
                    message.error(messageText.error);
                    this.setState({ loading: false, errorPhoto: null });
                  });
              }
            }
          }
        }
      });
    });
  };

  changeReset = () => {
    this.setState({ isReset: false });
  };

  onSelect = idProvince => this.loadDistricts(idProvince);

  onChangePurposes = e => this.setState({ purpose_of_loan: e.target.value });

  renderTypeRealEstate = () => {
    const { propertyUnitTypes } = this.state;
    return map(propertyUnitTypes, type => (
      <Option key={type.id}>{type.name}</Option>
    ));
  };

  selectPhoto = photos => {
    this.setState({ photos }, () => {
      if (!isNull(photos)) {
        this.setState({ errorPhoto: false });
      }
    });
  };

  handleFilter = (input, option) => {
    const text = removeAlias(option.props.children).toLowerCase();

    return text.indexOf(removeAlias(input.toLowerCase())) >= 0;
  };

  render() {
    const { form, loan, locale, isNew } = this.props;
    const { getFieldDecorator } = form;
    const {
      isReset,
      provinces,
      districts,
      purpose_of_loan,
      loanPurposes,
      loading,
      photos,
      photos_add,
      errorPhoto
    } = this.state;

    return (
      <WrapForm>
        <Card
          title={<div style={{ fontSize: 18 }}>Bất động sản</div>}
          type="inner"
        >
          <Form onSubmit={this.handleSubmit}>
            <h3>{lang.address[locale]}</h3>
            <Row gutter={15}>
              <Col sm={24} lg={12}>
                <Form.Item label="Tỉnh/Thành phố">
                  {getFieldDecorator('province_master_id', {
                    initialValue:
                      loan && loan.property.province_data
                        ? `${loan.property.province_data.id}`
                        : undefined,
                    rules: [
                      {
                        required: true,
                        message: lang.required_city[locale]
                      }
                    ]
                  })(
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Chọn tỉnh/thành phố"
                      optionFilterProp="children"
                      onChange={this.onSelect}
                      size={size}
                      filterOption={(input, option) =>
                        this.handleFilter(input, option)
                      }
                    >
                      {provinces.map(p => (
                        <Option key={p.id}>{p.name_with_type}</Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>

              <Col sm={24} lg={12}>
                <Form.Item label="Quận/Huyện">
                  {getFieldDecorator('district_master_id', {
                    initialValue:
                      loan && loan.property.district_data
                        ? `${loan.property.district_data.id}`
                        : undefined,

                    rules: [
                      {
                        required: true,
                        message: lang.required_county[locale]
                      }
                    ]
                  })(
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Chọn quận/huyện"
                      optionFilterProp="children"
                      size={size}
                      filterOption={(input, option) =>
                        this.handleFilter(input, option)
                      }
                    >
                      {districts.map(d => (
                        <Option key={d.id}>{d.name_with_type}</Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>

              <Col sm={24} span={24}>
                <Form.Item label="Địa chỉ">
                  {getFieldDecorator('street_address', {
                    initialValue: loan
                      ? `${loan.property.street_address}`
                      : undefined,
                    rules: [
                      {
                        required: true,
                        message: lang.required_street[locale]
                      }
                    ]
                  })(
                    <TextArea
                      placeholder={lang.street[locale]}
                      rows={3}
                    ></TextArea>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              validateStatus={errorPhoto ? 'error' : undefined}
              help={errorPhoto || undefined}
              label="Hình ảnh"
              required
            >
              {isNew ? (
                <ImageUploader
                  buttonStyles={{
                    background: theme.color.primary,
                    padding: `0 ${theme.size.space * 2}px`
                  }}
                  label="Hỗ trợ các định dạng jpeg|jpg|png|gif, file không quá 5mb"
                  className="image-upload-custom"
                  withIcon
                  buttonText="Chọn hình ảnh"
                  onChange={this.onDrop}
                  imgExtension={['.jpeg', '.jpg', '.gif', '.png']}
                  maxFileSize={5242880}
                  withPreview
                  fileSizeError=" quá 5mb "
                  fileTypeError="không hỗ trợ định dạng"
                  fileContainerStyle={{
                    boxShadow: 'none',
                    background: '#f5f7f9',
                    borderRadius: theme.size.borderRadius,
                    border: `1px dashed ${theme.color.border}`
                  }}
                />
              ) : (
                <EditImages
                  photos={photos}
                  photos_add={photos_add}
                  removePhoto={this.removePhoto}
                  removePhotoAdd={this.removePhotoAdd}
                  addPhoto={this.addPhoto}
                  isReset={isReset}
                  changeReset={this.changeReset}
                />
              )}
            </Form.Item>

            {/* <InputFile
              selectPhoto={this.selectPhoto}
              size="large"
              required
              label="Hình ảnh"
              defaultValue={
                loan && loan.property ? loan.property.photo_url : null
              }
              error={errorPhoto}
            /> */}

            <h3>{lang.infomation_real_estate[locale]}</h3>

            <Form.Item label={lang.type_real_estate[locale]}>
              {getFieldDecorator('unit_type_master_id', {
                initialValue: loan
                  ? `${loan.property.unit_type_master.id}`
                  : undefined,
                rules: [
                  {
                    required: true,
                    message: lang.required_type_real_estate[locale]
                  }
                ]
              })(
                <Select size={size} placeholder="Loại hình Bất động sản">
                  {this.renderTypeRealEstate()}
                </Select>
              )}
            </Form.Item>

            <Form.Item label={lang.purpose_of_loan[locale]}>
              {getFieldDecorator('loan_purpose_master_id', {
                initialValue: loan ? loan.loan_purpose_data.id : undefined,
                rules: [
                  {
                    required: true,
                    message: lang.required_type_real_estate[locale]
                  }
                ]
              })(
                <Radio.Group onChange={this.onChangePurposes}>
                  {loanPurposes.map(l => (
                    <Radio value={l.id} key={l.id}>
                      {l.name}
                    </Radio>
                  ))}
                </Radio.Group>
              )}
            </Form.Item>

            {purpose_of_loan === 2 && (
              <StyleBox>
                <Form.Item label="Hợp đồng mua Bất động sản đã kí xong?">
                  {getFieldDecorator('is_signed_on_real_estate_contract', {
                    initialValue:
                      loan && loan.is_signed_on_real_estate_contract
                        ? loan.is_signed_on_real_estate_contract
                        : false,

                    rules: [
                      {
                        required: true,
                        message: <span />
                      }
                    ]
                  })(
                    <Radio.Group>
                      <Radio value>Có</Radio>
                      <Radio value={false}>Không</Radio>
                    </Radio.Group>
                  )}
                </Form.Item>
              </StyleBox>
            )}

            {purpose_of_loan === 3 && (
              <StyleBox>
                <div style={{ display: 'grid' }}>
                  <Form.Item label="Trả nợ cho khoản vay Bất động sản hiện hữu">
                    {getFieldDecorator('intended_to_require_debt_date', {
                      initialValue:
                        loan && loan.intended_to_require_debt_date
                          ? moment(loan.intended_to_require_debt_date)
                          : undefined,
                      rules: [
                        {
                          required: true,
                          message: <span />
                        }
                      ]
                    })(
                      <DatePicker
                        format="DD-MM-YYYY"
                        size="large"
                        style={{ width: '100%' }}
                        placeholder="Ngày dự định cần phải nợ vay"
                      />
                    )}
                  </Form.Item>

                  <Form.Item>
                    {getFieldDecorator('cost_of_improvement', {
                      initialValue:
                        loan && loan.cost_of_improvement
                          ? loan.cost_of_improvement
                          : undefined,
                      rules: [
                        {
                          required: true,
                          message: <span />
                        }
                      ]
                    })(
                      <InputNumber
                        size="large"
                        formatter={value =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        placeholder="Bạn đã chi ra bao nhiêu tiền để tân trang bất động sản hiện tại"
                        style={{ width: '100%' }}
                      />
                    )}
                  </Form.Item>
                </div>
              </StyleBox>
            )}

            <h3>{lang.live_in_the_property[locale]}</h3>

            <Form.Item>
              {getFieldDecorator('live_in_investing_property', {
                initialValue: loan
                  ? loan.live_in_investing_property
                  : undefined,
                rules: [
                  {
                    required: true,
                    message: lang.required_type_real_estate[locale]
                  }
                ]
              })(
                <Radio.Group>
                  <Radio value>Có</Radio>
                  <Radio value={false}>Không</Radio>
                </Radio.Group>
              )}
            </Form.Item>

            <h3>{lang.information_about_the_interior[locale]}</h3>
            <Form.Item>
              {getFieldDecorator('have_interior_information', {
                initialValue: loan ? loan.have_interior_information : undefined,
                rules: [
                  {
                    required: true,
                    message: lang.required_type_real_estate[locale]
                  }
                ]
              })(
                <Radio.Group>
                  <Radio value>Có</Radio>
                  <Radio value={false}>Không</Radio>
                </Radio.Group>
              )}
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={loading}
              >
                {isNew ? 'Lưu' : lang.save_continue[locale]}
              </Button>
              {!isNew && (
                <Link to="/borrower/loans">
                  <Button style={{ marginLeft: 10 }}>
                    Danh sách khoản vay
                  </Button>
                </Link>
              )}
            </Form.Item>
          </Form>
        </Card>
      </WrapForm>
    );
  }
}

export default memo<PropertyProps, PropertyState>(
  Form.create()(withLang(withAuth(Property)))
);
