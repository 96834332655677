// @flow
import React from 'react';
import { Input, Form } from 'antd';

import { useSubscriber } from '../../api/subscriberApi';
import Button from '../Button';
import { FormSubsStyle, inputStyle } from '../FormSubs/style';
import lang from '../Footer/lang';

type FormSubsProps = {
  form: {
    getFieldDecorator: any,
    validateFields: any
  },
  locale: string
};

const regPhone = /(09[0|1|2|3|4|5|6|7|8|9]|08[3|4|5|7|9]|07[0|1|2|6|8|9]|05[6|8]|03[2|3|4|5|6|7|8|9]|02[0|1|2|3|4|5|6|7|8|9][0|1|2|3|4|5|6|7|8|9])+([0-9]{7})\b/g;

const FormPhone = ({ form, locale }: FormSubsProps) => {
  const { getFieldDecorator, validateFields } = form;
  const { loading, handleSubscriber } = useSubscriber();

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (loading) {
      return null;
    }

    validateFields((error, values) => {
      if (!error) {
        const formData = new FormData();
        formData.append('phone_number', values.phone_number);

        handleSubscriber(formData);
      }
    });
  };

  return (
    <FormSubsStyle>
      <Form onSubmit={handleSubmit}>
        <Form.Item style={{ margin: 0, textAlign: 'left' }}>
          {getFieldDecorator('phone_number', {
            rules: [
              {
                required: true,
                message: lang.footer3.formPhone.validate.required[locale]
              },
              {
                validator: (rule, value, callback) => {
                  const valid = regPhone.test(value);
                  if (value && !valid) {
                    callback(lang.footer3.formPhone.validate.isPhone[locale]);
                  }

                  callback();
                }
              }
            ]
          })(
            <Input
              placeholder={lang.footer3.formPhone.input[locale]}
              style={inputStyle}
            />
          )}
        </Form.Item>

        <div className="btnForm" onClick={handleSubmit} role="presentation">
          <Button disabled={loading} height={35}>
            {lang.footer3.button[locale]}
          </Button>
        </div>
      </Form>
    </FormSubsStyle>
  );
};

export default Form.create()(FormPhone);
