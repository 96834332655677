import formValidate from './formValidate';
import { theme } from '../configs';

const formatDate = 'DD-MM-YYYY';

const formatNumberCurrency = value =>
  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

// pending_submission, problem, in_progress, closed, archived, và approved
const colorStatusLoan = status => {
  if (status === 'approved') return theme.color.status.success;

  if (
    status === 'pending_submission' ||
    status === 'in_progress' ||
    status === 'archived' ||
    status === 'problem'
  )
    return theme.color.status.warning;

  if (status === 'close') return null;
  return null;
};

const colorStatusInvestment = status => {
  if (status === 'approved') return theme.color.status.success;

  if (status === 'pending') return theme.color.status.warning;

  if (status === 'rejected') return null;
  return null;
};

// format money
const formatMoneyStrToNumber = money => {
  const remove_vnd = money.replace('VND', '').trim();

  return parseInt(remove_vnd.replace(/,/g, ''), 0);
};

export {
  formValidate,
  formatDate,
  formatNumberCurrency,
  colorStatusLoan,
  colorStatusInvestment,
  formatMoneyStrToNumber
};
