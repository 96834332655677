import React from 'react';
import { Link } from '@reach/router';
import { Row, Col } from 'antd';

import Header from '../Header';
import { HomeSection, HomeSectionContainer } from '../../pages/Home/style';
import { HeadStyle, HeaderStyle, HeadContent, HeadImg } from './style';

import bg from './bg.png';
import { withLang } from '../../hoc';
import lang from './lang';

const Head = ({ img, path, title, locale }) => {
  return (
    <HeadStyle>
      <HeadImg>
        <img
          style={{ position: 'absolute', right: 0, top: 0 }}
          src={bg}
          alt=""
        />
      </HeadImg>
      <HeaderStyle>
        <Header />
      </HeaderStyle>

      <HeadContent>
        <HomeSection style={{ paddingTop: 50, paddingBottom: 0 }}>
          <HomeSectionContainer>
            <Row align="middle" gutter={30} type="flex">
              <Col md={12}>
                <h2>{title}</h2>

                <ul>
                  <li>
                    <Link to="/">{lang.home[locale]}</Link>
                  </li>
                  <li>{title}</li>
                </ul>
              </Col>
              {img && (
                <Col md={12}>
                  <img src={img} alt={title} />
                </Col>
              )}
            </Row>
          </HomeSectionContainer>
        </HomeSection>
      </HeadContent>
    </HeadStyle>
  );
};

export default withLang(Head);
