import { useState, useEffect } from 'react';

const useLocation = ({ google, address }) => {
  const [loading, setLoading] = useState(true);
  const [center, setCenter] = useState({});

  useEffect(() => {
    const geocoder = new google.maps.Geocoder();

    geocoder.geocode({ address }, (results, status) => {
      if (status === 'OK') {
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();

        setCenter({ lat, lng });
        setLoading(false);
      } else {
        // console.log('status', status);
      }
    });
  }, [address, google.maps.Geocoder]);

  return [loading, center];
};

// eslint-disable-next-line
export { useLocation };
