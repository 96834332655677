export default {
  color: {
    text: '#071b2d',
    border: '#eee',
    primary: '#1890ff',
    secondary: '#fecb00',
    bodyBg: '#fafafa',
    status: {
      success: '#87d068',
      warning: '#f50'
    }
  },
  size: {
    button: 50,
    space: 15,
    borderRadius: 3,
    container: 1170
  },
  media: {
    sm: 767,
    md: 991,
    lg: 1199
  }
};
