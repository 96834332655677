import styled from 'styled-components';
import { Link } from '@reach/router';

import { theme } from '../../configs';

const height_header = 55;

const HeaderWrap = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.size.space}px ${theme.size.space * 2}px;
  // background: #fff;
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`;

const PhoneStyle = styled.div`
  font-size: 16px;
  border-left: 1px solid ${theme.color.border};
  padding-left: ${theme.size.space}px;
  margin-left: ${theme.size.space}px;
`;

const NavStyle = styled.div`
  margin-left: ${theme.size.space * 3}px;
`;

const IconMenuStyle = styled.div`
  cursor: pointer;
  height: 12px;
  width: 22px;
  position: relative;

  &:after,
  &:before {
    content: '';
  }

  &:after,
  &:before,
  span {
    position: absolute;
    display: block;
    height: 2px;
    width: 100%;
    background: ${theme.color.text};
  }

  &:before {
    transform: ${({ show }) => (show ? 'rotate(45deg)' : 'rotate(0)')};
    top: ${({ show }) => (show ? '50%' : '0%')};
  }

  &:after {
    transform: ${({ show }) => (show ? 'rotate(-45deg)' : 'rotate(0)')};
    top: ${({ show }) => (show ? '50%' : '100%')};
  }

  span {
    transition: all 0.25s ease-in-out;
    top: 50%;
    display: ${({ show }) => (show ? 'none' : 'block')};
  }
`;
const MenuMobileStyle = styled.div`
  position: fixed;
  background: #fff;
  bottom: 0;
  left: 0;
  right: 0;
  top: 60px;
  z-index: 999;
  overflow-y: auto;
`;

const SectionMenuMobileStyle = styled.div`
  padding: ${theme.size.space}px ${theme.size.space * 2}px;
  border-bottom: 1px solid ${theme.color.border};
`;

const HeaderRightActionStyle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: ${height_header}px;

  .name {
    
    line-height: 1;
    margin-right: 5px;
    margin-left: 5px;
    position: relative;
  }

  span {
    font-weight: 600;
    display: block;
    magin-bottom: 3px;
  }

  small {
    position: absolute;
    white-space: nowrap
    bottom: -${theme.size.space}px;
    font-size: 10px;
  }
`;

const MenuItemLinkStyle = styled(Link)`
  display: block;
  padding: ${theme.size.space - 10}px ${theme.size.space - 5}px;
  font-weight: 600;
  color: ${theme.color.text};

  &:hover {
    color: #33e2a0;
  }
`;

const MenuItemStyle = styled.a`
  display: block;
  padding: ${theme.size.space - 10}px  ${theme.size.space - 5}px
  font-weight: 600;
  color: ${theme.color.text};

  &:hover {
    color: #33e2a0;
  }
`;

const MenuItemMobileStyle = styled.a`
  display: block;
  padding: ${theme.size.space}px 0;
  font-weight: 600;
  color: ${theme.color.text};

  &:hover {
    color: #33e2a0;
  }
`;

const NavLinkStyle = styled(Link)`
  color: #333;
  font-size: 16px;
  font-weight: 600;

  &:hover {
    color: #33e2a0;
  }
`;

const SelectLangTitle = styled.span`
  color: #333;
  font-size: 16px;
  font-weight: 600;

  &:hover {
    color: #33e2a0;
  }
`;

export {
  HeaderWrap,
  HeaderLeft,
  HeaderRight,
  PhoneStyle,
  NavStyle,
  IconMenuStyle,
  MenuMobileStyle,
  MenuItemMobileStyle,
  MenuItemLinkStyle,
  MenuItemStyle,
  SectionMenuMobileStyle,
  HeaderRightActionStyle,
  NavLinkStyle,
  SelectLangTitle
};
