import { useState, useEffect } from 'react';
import api from '../../api';

const useProvinces = () => {
  const [provinces, setProvinces] = useState([]);

  useEffect(() => {
    const loadProvices = async () => {
      try {
        const res = await api.masterApi.provinces();
        return setProvinces(res.data);
      } catch (error) {
        return null;
      }
    };

    loadProvices();
  }, []);

  return [provinces];
};

const useDistricts = id => {
  const [districts, setDistricts] = useState([]);

  useEffect(() => {
    const loadDistricts = async () => {
      try {
        const res = await api.masterApi.districts(id);
        return setDistricts(res.data);
      } catch (error) {
        return null;
      }
    };

    loadDistricts();
  }, [id]);

  return [districts];
};

const useBusinessTypes = () => {
  const [business_types, setBusinessTypes] = useState([]);

  useEffect(() => {
    const loadBusinessTypes = async () => {
      try {
        const res = await api.masterApi.business_types();
        return setBusinessTypes(res.data);
      } catch (error) {
        return null;
      }
    };
    loadBusinessTypes();
  }, []);

  return [business_types];
};

const usePositions = () => {
  const [positions, setPosotions] = useState([]);

  useEffect(() => {
    const loadPositions = async () => {
      try {
        const res = await api.masterApi.position_types();
        return setPosotions(res.data);
      } catch (error) {
        return null;
      }
    };

    loadPositions();
  }, []);

  return [positions];
};

export { useProvinces, useDistricts, useBusinessTypes, usePositions };
