import { useState } from 'react';
import Axios from 'axios';
import { message } from 'antd';

const url = 'https://admin.finrei.com/api/v1/subscribers';

const subscriberApi = data =>
  Axios({
    method: 'POST',
    url,
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

const useSubscriber = () => {
  const [loading, setLoading] = useState(false);

  const handleSubscriber = data => {
    setLoading(true);
    subscriberApi(data)
      .then(() => {
        setLoading(false);
        message.success('Đăng ký thành công');
      })
      .catch(err => {
        setLoading(false);
        message.error(err.message);
      });
  };

  return {
    loading,
    handleSubscriber
  };
};

export { subscriberApi, useSubscriber };
