// @flow
import React, { memo } from 'react';
import { Link } from '@reach/router';
import { Button, Card, Icon } from 'antd';

import Layout from '../../layout';
import LoansTable from './LoansTable';
import { theme } from '../../configs';

const LoanHome = () => {
  return (
    <Layout title="Danh sách khoản vay" header={false}>
      <Card
        type="inner"
        bodyStyle={{ padding: '1px 0 0' }}
        headStyle={{
          paddingLeft: '15px',
          paddingRight: '15px',
          borderBottom: `1px solid ${theme.color.border}`
        }}
        title={<span style={{ fontSize: 18 }}>Danh sách khoản vay</span>}
        extra={
          <Link to="/loan/new">
            <Button type="primary">
              <Icon type="plus" /> khoản vay mới
            </Button>
          </Link>
        }
      >
        <LoansTable />
      </Card>
    </Layout>
  );
};

export default memo(LoanHome);
