// @flow
import React, { type Node, useEffect } from 'react';

type Context = {
  locale: string,
  handleLocale: Function
};

const defaultLang = 'vi';

const initialContext = {
  locale: defaultLang,
  handleLocale: () => {}
};

const langContext = React.createContext<Context>(initialContext);
const { Provider } = langContext;

type LangContextProviderProps = {
  children: Node
};

export const LangContextProvider = ({ children }: LangContextProviderProps) => {
  const [locale, setLocale] = React.useState(defaultLang);

  const handleLocale = _locale => {
    localStorage.setItem('lang', _locale);
    setLocale(_locale);
  };

  useEffect(() => {
    const lang = localStorage.getItem('lang');
    if (lang) {
      setLocale(lang);
    }
  }, []);

  return <Provider value={{ locale, handleLocale }}>{children}</Provider>;
};

export default langContext;
