// @flow
import React, { type Node } from 'react';
import { message } from 'antd';

import api from '../api';
import { Loading } from '../components';

type Context = {
  user: null,
  logout: Function,
  getUser: Function,
  authentication: boolean
};

const initContext = {
  authentication: false,
  logout: () => {},
  getUser: () => {},
  user: null
};

const authContext = React.createContext<Context>(initContext);

const { Provider } = authContext;

type AuthContextProviderProps = {
  children: Node
};

export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const [user, setUser] = React.useState(null);
  const [authentication, setAuthentication] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const getUser = userRes => {
    setLoading(true);

    api.authApi
      .user({ token: userRes.token, userId: userRes.id })
      .then(res => {
        const userDetail = { ...userRes, ...res.data };

        setUser(userDetail);
        const userJson = JSON.stringify(userDetail);

        setAuthentication(true);
        localStorage.setItem('user', userJson);
        setLoading(false);
      })
      .catch(() => {
        setUser(null);
        localStorage.removeItem('user');
        setAuthentication(false);
        setLoading(false);
      });
  };

  const removeAuth = () => {
    setUser(null);
    setAuthentication(false);
    localStorage.removeItem('user');
  };

  const logout = () => {
    if (user) {
      const { token } = user;
      api.authApi
        .logout(token)
        .then(() => {
          message.success('Đăng xuất thành công');
          removeAuth();
        })
        .catch(() => {
          message.error('Đăng xuất thất bại');
        });
    }
  };

  React.useEffect(() => {
    const userLocal = localStorage.getItem('user');
    if (userLocal) {
      const userJson = JSON.parse(userLocal);
      getUser(userJson);
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) return <Loading />;

  return (
    <Provider
      value={{ authentication, user, logout, getUser, setUser, removeAuth }}
    >
      {children}
    </Provider>
  );
};

export default authContext;
