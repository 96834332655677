import React, { useContext } from 'react';

import { authContext } from '../context';

const withAuth = Component => {
  return props => {
    const auth = useContext(authContext);

    return <Component {...auth} {...props} />;
  };
};

export default withAuth;
