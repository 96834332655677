const email = {
  required: {
    vi: 'Không để trống email của bạn',
    en: 'Please input your email!'
  },
  type: {
    vi: 'Không phải định dạng email',
    en: 'Please input your email!'
  }
};

const password = {
  required: {
    vi: 'Không để trống password của bạn',
    en: 'Please input your password!'
  },
  validate_password: {
    vi: 'Password should be more than 8-20 characters',
    en: 'Password should be more than 8-20 characters'
  },
  new_password: {
    vi: 'Pleas input new confirm password',
    en: 'Pleas input new confirm password'
  },
  confirm_password: {
    vi: 'Two passwords that you enter is inconsistent!',
    en: 'Two passwords that you enter is inconsistent!'
  }
};

export default { email, password };
