import React, { Suspense, lazy } from 'react';
import { Router } from '@reach/router';

import { Store } from '../context';

// components
import { Loading } from '../components';
import {
  GuestRouter,
  BorrowerRouter,
  InvestorRouter,
  InvestorVerifyRouter
} from './authRoute';
import LoanHome from '../pages/LoanHome';
import BorrowerProfile from '../pages/BorrowerProfile';

// import InvestmentDetail from '../pages/InvestmentDetail';

// pages import
const Home = lazy(() => import('../pages/Home'));

const Signin = lazy(() => import('../pages/Signin'));
const Signup = lazy(() => import('../pages/Signup'));
const SignupHome = lazy(() => import('../pages/Signup/SignupHome'));
const SignupInvestor = lazy(() => import('../pages/Signup/SignupInvestor'));
const SignupBorrower = lazy(() => import('../pages/Signup/SignupBorrower'));
const Password = lazy(() => import('../pages/Password'));
const ForgotPassword = lazy(() => import('../pages/Password/ForgotPassword'));
const ResetPassword = lazy(() => import('../pages/Password/ResetPassword'));
const ConfirmSignup = lazy(() => import('../pages/ConfirmSignup'));

const BorrowerDashboard = lazy(() => import('../pages/BorrowerDashboard'));

const Loans = lazy(() => import('../pages/Loans'));
const LoanNew = lazy(() => import('../pages/LoanNew'));
const LoanEdit = lazy(() => import('../pages/LoanEdit'));
const LoanDetail = lazy(() => import('../pages/LoanDetail'));

const InvestorDashboard = lazy(() => import('../pages/InvestorDashboard'));
const InvestorDbHome = lazy(() => import('../pages/InvestorDbHome'));
const CompleteInvestment = lazy(() => import('../pages/CompleteInvestment'));
const InvestorProfileComplete = lazy(() =>
  import('../pages/InvestorProfileComplete')
);
const Investment = lazy(() => import('../pages/Investment'));
const InvestmentDetail = lazy(() => import('../pages/InvestmentDetail'));
const LandingPage = lazy(() => import('../pages/LandingPage'));
const InvestmentType = lazy(() => import('../pages/InvestmentType'));

const AddFund = lazy(() => import('../pages/AddFun'));

const AboutUs = lazy(() => import('../pages/AboutUs'));

const Faq = lazy(() => import('../pages/Faq'));

const NotFound = lazy(() => import('../pages/NotFound'));

const AppRouter = () => (
  <Store>
    <Suspense fallback={<Loading />}>
      <Router>
        <Home path="/" />
        <GuestRouter component={Signin} path="/signin" />

        <GuestRouter component={Signup} path="/signup">
          <SignupHome path="/" />
          <SignupInvestor path="/investor" />
          <SignupBorrower path="/borrower" />
          <NotFound default header={false} />
        </GuestRouter>

        <AboutUs path="/about-us" />
        <Faq path="/faqs" />

        <GuestRouter component={ConfirmSignup} path="/confirmations" />

        <GuestRouter component={Password} path="/password">
          <ForgotPassword path="/forgot" />
          <ResetPassword path="/edit" />
          <NotFound default header={false} />
        </GuestRouter>

        <BorrowerRouter component={BorrowerDashboard} path="borrower">
          <BorrowerProfile path="/profile" />
          <LoanHome path="/loans" />
          <NotFound default header={false} />
        </BorrowerRouter>

        <LoanDetail path="/loan/detail/:id" />

        <BorrowerRouter component={Loans} path="loan">
          <LoanNew path="new" />
          <LoanNew path="new/:id" />
          <LoanEdit path="edit/:id" />
          <NotFound default header={false} />
        </BorrowerRouter>

        <InvestorRouter component={InvestorDashboard} path="investor">
          <InvestorDbHome path="/dashboard" />
          <InvestorDbHome path="/dashboard/:tab" />
          <InvestorProfileComplete path="/profile" />
          <CompleteInvestment path="/hoan-thanh-khoan-dau-tu" />
          <InvestorVerifyRouter component={Investment} path="/dau-tu" />
          <InvestorVerifyRouter
            component={InvestmentType}
            path="/dau-tu/t/:filter"
          />
          <InvestorVerifyRouter
            component={InvestmentDetail}
            path="/dau-tu/d/:id"
          />
          <LandingPage path="/landing_page/:id" />
          <InvestorVerifyRouter component={AddFund} path="/add-fund" />
          <NotFound default header={false} />
        </InvestorRouter>

        <NotFound default />
      </Router>
    </Suspense>
  </Store>
);

export default AppRouter;
