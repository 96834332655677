import axios from './axios';

export default {
  loan: (id, token) =>
    axios({
      url: `/borrowers/loans/${id}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }),
  loans: token =>
    axios({
      url: `/borrowers/loans`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      params: {
        per_page: 100
      }
    })
};
