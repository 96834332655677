// @flow
import React from 'react';
import { Table, Button, Icon, Tooltip, Tag, Popover, Progress } from 'antd';
import { map } from 'lodash';
import { Link } from '@reach/router';
import moment from 'moment';

import {
  formatNumberCurrency,
  colorStatusLoan,
  colorStatusInvestment
} from '../../ultils';
import { useBorrower } from '../../context/borrowerContext';

const linkDetail = id => `/loan/detail/${id}`;
const linkEdit = id => `/loan/edit/${id}`;

// list investment in loan
const columns1 = [
  {
    title: 'Nhà đầu tư',
    dataIndex: 'investor',
    key: 'investor',
    render: investor => {
      if (!investor) return null;

      return (
        <div>
          <div
            style={{ fontSize: 18, fontWeight: 600 }}
          >{`${investor.given_name} ${investor.family_name}`}</div>
          <span style={{ display: 'block', margin: '5px 0' }}>
            {investor.email}
          </span>
          <span>{investor.phone_number}</span>
        </div>
      );
    }
  },
  {
    title: 'Thời điểm đầu tư',
    dataIndex: 'created_at',
    key: 'created_at',
    render: date => moment(date).format('DD/MM/YYYY')
  },
  {
    title: 'Số tiền (vnd)',
    dataIndex: 'amount',
    key: 'amount',
    render: amount => formatNumberCurrency(amount)
  },
  {
    title: 'Trạng thái ',
    dataIndex: '',
    key: 'status',
    render: ({ state, state_text }: { state: String, state_text: String }) => {
      return <Tag color={colorStatusInvestment(state)}>{state_text}</Tag>;
    }
  }
];

// investment list
const columns = [
  {
    title: <b>Địa chỉ</b>,
    dataIndex: '',
    key: '',
    width: 200,
    render: ({
      property,
      id,
      state
    }: {
      property: any,
      id: string,
      state: string
    }) => {
      const content = (
        <div
          style={{
            maxWidth: 200,
            textAlign: 'center',
            fontWeight: 600,
            lineHeight: 2
          }}
        >{`${property.street_address || ''}, ${property.district ||
          ''}, ${property.province || ''}`}</div>
      );
      return (
        <Popover content={content} title={null}>
          <Link to={state === 'approved' ? linkDetail(id) : linkEdit(id)}>
            <span
              style={{
                display: 'inline-block',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                width: '150px',
                overflow: 'hidden'
              }}
            >
              {`${property.street_address || ''}, ${property.district ||
                ''}, ${property.province || ''}`}
            </span>
          </Link>
        </Popover>
      );
    }
  },
  // {
  //   title: <b>Giá thị trường</b>,
  //   dataIndex: 'property_market_price',
  //   key: 'property_market_price',
  //   align: 'center',
  //   render: price =>
  //     price ? (
  //       <span>{formatNumberCurrency(price)}</span>
  //     ) : (
  //       <Tooltip placement="top" title="Chưa cập nhật">
  //         <Icon type="stop" style={{ fontSize: 20 }} />
  //       </Tooltip>
  //     )
  // },
  {
    title: <b>Giá dự kiện</b>,
    dataIndex: 'purchase_price',
    key: 'purchase_price',
    align: 'center',
    render: price =>
      price ? (
        <span>{formatNumberCurrency(price)}</span>
      ) : (
        <Tooltip placement="top" title="Chưa cập nhật">
          <Icon type="stop" style={{ fontSize: 20 }} />
        </Tooltip>
      )
  },
  {
    title: <b>Cần đầu tư</b>,
    dataIndex: 'total_requested_amount',
    key: 'total_requested_amount',
    align: 'center',
    render: price =>
      price ? (
        <span>{formatNumberCurrency(price)}</span>
      ) : (
        <Tooltip placement="top" title="Chưa cập nhật">
          <Icon type="stop" style={{ fontSize: 20 }} />
        </Tooltip>
      )
  },
  {
    title: <b>Đã đầu tư</b>,
    dataIndex: '',
    key: 'investments',
    align: 'center',
    render: loan => {
      const amount_invested = loan.investments.reduce(
        (a, ins) => a + ins.amount,
        0
      );
      const percent = (amount_invested / loan.total_requested_amount) * 100;
      return (
        <>
          <Progress percent={percent} showInfo={false} />
          {formatNumberCurrency(
            loan.investments.reduce((a, ins) => a + ins.amount, 0)
          )}
        </>
      );
    }
  },

  {
    title: <b>Ngày kết thúc</b>,
    align: 'center',
    dataIndex: 'preferred_close_date',
    key: 'preferred_close_date',
    render: preferred_close_date =>
      preferred_close_date ? (
        moment(preferred_close_date).format('DD/MM/YYYY')
      ) : (
        <Tooltip placement="top" title="Chưa cập nhật">
          <Icon type="stop" style={{ fontSize: 20 }} />
        </Tooltip>
      )
  },
  {
    title: <b>Trạng thái</b>,
    align: 'center',
    dataIndex: '',
    key: 'state',
    render: ({ state, state_text }: { state: String, state_text: String }) => {
      const color = colorStatusLoan(state);
      return <Tag color={color}>{state ? state_text : 'Chờ xác nhận'}</Tag>;
    }
  },
  {
    title: '',
    dataIndex: '',
    key: '',
    width: 100,
    align: 'right',
    render: ({ id, state }: { id: string, state: string }) =>
      state === 'approved' ? (
        <Link to={linkDetail(id)}>
          <Tooltip placement="top" title="Xem">
            <Button size="small">
              <Icon type="eye" />
            </Button>
          </Tooltip>
        </Link>
      ) : (
        <Link to={linkEdit(id)}>
          <Tooltip placement="top" title="Chỉnh sửa">
            <Button size="small">
              <Icon type="edit" />
            </Button>
          </Tooltip>
        </Link>
      )
  }
];

const InvestmentTable = ({ investments }: { investments: any }) => {
  const dataSource = investments.map(i => ({ ...i, key: i.id }));

  return (
    <Table columns={columns1} dataSource={dataSource} pagination={false} />
  );
};

const LoansTable = () => {
  const { state } = useBorrower();
  const { loading, loans } = state;

  const dataSource = map(loans, l => ({ ...l, key: l.id }));
  const pagination = { showSizeChanger: true, size: 'small' };

  const expandedRowRender = record => {
    const { investments } = record;
    if (investments.length === 0)
      return <div style={{ textAlign: 'center' }}>Chưa có đầu tư</div>;
    return <InvestmentTable investments={investments} />;
  };

  return (
    <Table
      style={{ borderRadius: 0 }}
      columns={columns}
      dataSource={dataSource}
      expandedRowRender={expandedRowRender}
      loading={loading}
      pagination={pagination}
      scroll={{ x: 768 }}
    />
  );
};

export default LoansTable;
