export default {
  footer0: {
    text1: {
      vi: 'Công ty cổ phần đầu tư FinREI',
      en: 'FinREI Investment Joint Stock Company'
    },
    text2: {
      en: '31 Han Thuyen, Ben Nghe Ward, Dist.1, HCMC',
      vi: '31 Hàn Thuyên , P. Bến Nghé, Q.1, TP.HCM'
    }
  },
  footer1: {
    title: { vi: 'Danh mục', en: 'Category' },
    links: {
      link1: {
        vi: 'Trang chủ',
        en: 'Home Page'
      },
      link2: {
        vi: 'Về FinRei',
        en: 'About FinREI'
      },
      link3: {
        vi: 'Câu hỏi thường gặp',
        en: 'FAQs'
      },
      link4: {
        vi: 'Cơ hội việc làm',
        en: 'Careers'
      }
    }
  },

  footer2: {
    title: { vi: 'Liên kết', en: 'Links' },
    links: {
      link1: {
        vi: 'Kiến thức Tài chính',
        en: 'Financial Knowledge'
      },
      link2: {
        vi: 'Kiến thức Đầu tư',
        en: 'Investment Knowledge'
      },
      link3: {
        vi: 'Điều khoản dịch vụ',
        en: 'Terms of Service'
      },
      link4: {
        vi: 'Chính sách bảo mật',
        en: 'Privacy Policy'
      }
    }
  },
  footer3: {
    title: { vi: 'Liên hệ tư vấn', en: 'Contact Us' },
    formPhone: {
      input: {
        vi: 'Số điện thoại',
        en: 'Phone number'
      },
      validate: {
        required: {
          vi: 'Nhập số điện thoại',
          en: 'Enter your phone number'
        },
        isPhone: {
          vi: 'Số điện thoại không đúng',
          en: 'Phone number is incorrect'
        }
      }
    },
    formEmail: {
      input: {
        vi: 'Email',
        en: 'Email'
      },
      validate: {
        required: {
          vi: 'Nhập địa chỉ email',
          en: 'Enter your email'
        },
        isEmail: {
          vi: 'Email không đúng',
          en: 'Email is incorrect'
        }
      }
    },

    button: {
      vi: 'Đăng ký',
      en: 'Register'
    }
  },
  warning: {
    vi:
      'FinREI.com không đưa ra bất kỳ lời chào mời đầu tư vào một sản phẩm Bất Động Sản cụ thể nào tới bạn. FinREI.com cung cấp nền tảng công nghệ để giúp bạn tìm kiếm và tiếp cận các sản phẩm Đầu tư Bất Động Sản đơn giản, minh bạch, và hiệu quả. Mọi quyết định đầu tư là do bạn cân nhắc và hoàn toàn quyết định. Trong lĩnh vực đầu tư, bên cạnh lợi nhuận, rủi ro là yếu tố luôn song hành.',
    en:
      'FinREI.com does not make any offers to invest in any particular Real Estate products. FinREI.com provides a technology platform to help you find and access simple, transparent, and effective Real Estate products. All investment decisions are yours to consider and fully decide. In the field of investment, besides profit, risk is always a parallel factor.'
  }
};
