export default {
  // tab
  property: {
    vi: 'Bất động sản',
    en: 'Property'
  },
  loan: {
    vi: 'Thông tin về Nguồn vốn',
    en: 'Loan'
  },
  borrower: {
    vi: 'Thông tin Liên lạc',
    en: 'Borrower'
  },
  guarantor: {
    vi: 'Người bảo trợ',
    en: 'Guarantor'
  },
  experience: {
    vi: 'Kinh nghiệm đầu tư',
    en: 'Experience'
  },
  /**
   * STEP NEW LOAN
   * 1. property
   * 2. loan
   * */

  save_continue: {
    vi: 'Lưu và tiếp tục',
    en: 'Save and Continue'
  },

  // Tab 1: property
  address: {
    vi: '1. Địa chỉ',
    en: '1. What Is the Address of the Property?'
  },
  street: {
    vi: 'Đường',
    en: 'Street'
  },
  required_street: {
    vi: 'Điền tên đường',
    en: `Type street's name`
  },
  ward: {
    vi: 'Phường',
    en: 'Ward'
  },
  county: {
    vi: 'Quận',
    en: 'County'
  },
  required_county: {
    vi: 'Chọn thành phố',
    en: 'Select county'
  },
  city: {
    vi: 'Thành phố',
    en: 'City'
  },
  required_city: {
    vi: 'Chọn thành phố',
    en: 'Select city'
  },
  //
  infomation_real_estate: {
    vi: '2. Thông tin về Bất động sản',
    en: '2. Information about Real Estate'
  },
  type_real_estate: {
    vi: 'Loại hình Bất động sản',
    en: 'Type of Real Estate:'
  },
  required_type_real_estate: {
    vi: 'Loại hình Bất động sản',
    en: 'Select type of Real Estate:'
  },
  type1: {
    vi: 'Biệt thự, villa, Penthouse',
    en: 'Villa, Penthouse'
  },
  type2: {
    vi: 'Căn hộ Chung cư',
    en: 'Apartment'
  },
  type3: {
    vi: 'Nhà trong hẻm / ngõ',
    en: 'House in alley'
  },
  type4: {
    vi: 'Nhà mặt tiền',
    en: 'House frontage'
  },
  //
  live_in_the_property: {
    vi: '3. Bạn có dự định sinh sống tại bất động sản đang muốn đâu tư ko?',
    en: '3. Do you plan to live in the property you want to invest?'
  },

  purpose_of_loan: {
    vi: 'Lí do cần nguồn tài chính',
    en: 'Purpose of Loan'
  },
  information_about_the_interior: {
    vi:
      '4. Bạn có các thông tin về nội thất bên trong của bất động sản đang muốn đầu tư ko?',
    en:
      '4. Do you have information about the interior of the property you want to invest?'
  },

  // Loan
  preferred_close_date: {
    vi: 'Ngày dự kiến muốn hoàn tất mua/đầu tư vào Bất động sản',
    en: 'Preferred close date'
  },
  required_preferred_close_date: {
    vi: 'Chọn ngày dự kiến',
    en: 'Select preferred close date'
  },

  purchase_price: {
    vi: 'Giá dự kiến của bất động sản trong 12 tháng tới',
    en: 'Purchase price'
  },
  required_purchase_price: {
    vi: 'Chọn giá dự kiến',
    en: 'Select purchase price'
  },

  property_market_price: {
    vi: 'Giá trị thị trường của bất động sản',
    en: 'Property market price'
  },
  required_property_market_price: {
    vi: 'Chọn giá thị trường của bất động sản',
    en: 'Select property market price'
  },

  is_seller_share_costs_documents: {
    vi: 'Người Bán có chia sẻ chi phí (giấy tờ) nào với bạn không?',
    en: 'Người Bán có chia sẻ chi phí (giấy tờ) nào với bạn không?'
  },
  required_is_seller_share_costs_documents: {
    vi: 'Vui lòng chọn',
    en: 'Select option'
  },

  is_new_buyer_pay_additional_cost: {
    vi:
      'Nếu bạn không mua nữa mà chuyển nhượng hợp đồng mua cho người khác thì người mua mới này có phải trả thêm chi phí gì cho người bán không?',
    en:
      'Nếu bạn không mua nữa mà chuyển nhượng hợp đồng mua cho người khác thì người mua mới này có phải trả thêm chi phí gì cho người bán không?'
  },
  required_is_new_buyer_pay_additional_cost: {
    vi: 'Vui lòng chọn',
    en: 'Select option'
  },

  is_rehab_required: {
    vi: '2. Sau khi mua xong bất động sản, bạn có dự định tân trang lại không?',
    en: '2. Sau khi mua xong bất động sản, bạn có dự định tân trang lại không?'
  },
  required_is_rehab_required: {
    vi: 'Vui lòng chọn',
    en: 'Select option'
  },

  total_rehad_cost: {
    vi: 'Bạn dự định chi ra bao nhiêu tiền cho việc tân trang này',
    en: ' Bạn dự định chi ra bao nhiêu tiền cho việc tân trang này'
  },
  required_total_rehad_cost: {
    vi: 'Vui lòng điền thông tin',
    en: 'Select option'
  },

  after_repaired_property_price: {
    vi: 'Sau khi được tân trang, giá trị bất động sản sẽ vào khoảng bao nhiêu?',
    en: 'Sau khi được tân trang, giá trị bất động sản sẽ vào khoảng bao nhiêu?'
  },
  required_after_repaired_property_price: {
    vi: 'Vui lòng điền thông tin',
    en: 'Select option'
  },

  total_requested_amount: {
    vi: 'Tổng số vốn bạn đang tìm kiếm',
    en: 'Tổng số vốn bạn đang tìm kiếm'
  },
  required_total_requested_amount: {
    vi: 'Vui lòng điền thông tin',
    en: 'Select option'
  },

  total_cost: {
    vi: 'Tổng gía trị thị trường của Bất động sản bạn đang muốn mua',
    en: 'Tổng gía trị thị trường của Bất động sản bạn đang muốn mua'
  },
  required_total_cost: {
    vi: 'Vui lòng điền thông tin',
    en: 'Select option'
  },

  loan_to_value_ratio: {
    vi: 'Tỷ lệ cho vay/giá trị? (%)',
    en: 'Tỷ lệ cho vay/giá trị? (%)'
  },
  required_loan_to_value_ratio: {
    vi: 'Vui lòng điền thông tin',
    en: 'Select option'
  },

  // Experience
  has_real_estate_investment: {
    vi: 'Bạn đã từng Đầu tư Bất động sản',
    en: 'Bạn đã từng Đầu tư Bất động sản'
  },
  required_has_real_estate_investment: {
    vi: 'Làm ơn chọn',
    en: 'Select option'
  },
  label_has_real_estate_investment: {
    vi: 'Đã từng Đầu tư',
    en: 'Đã từng Đầu tư'
  },
  required_label_has_real_estate_investment: {
    vi: 'Chọn nơi đã từng đầu tư',
    en: 'Select option'
  },
  label_has_not_real_estate_investment: {
    vi: 'Chưa từng Đầu tư',
    en: 'Chưa từng Đầu tư'
  },
  investment_experience_years_master_id: {
    vi: 'Số năm kinh nghiệm trong Đầu tư Bất động sản',
    en: 'Số năm kinh nghiệm trong Đầu tư Bất động sản'
  },
  required_investment_experience_years_master_id: {
    vi: 'Số năm kinh nghiệm trong Đầu tư Bất động sản',
    en: 'Số năm kinh nghiệm trong Đầu tư Bất động sản'
  }
};
