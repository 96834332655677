import styled, { keyframes } from 'styled-components';
import { theme } from '../../configs';
import bgw from './assets/bgw.png';

const iqWorkBefore = keyframes`
0% {
  background-position: 0% bottom;
}

100% {
  background-position: 100% bottom;
}
`;

const HomeSection = styled.div`
  padding: 80px 0;
  background: ${({ gray }) => (gray ? '#f4f4f4' : 'transparent')};
  position: relative;

  @media (max-width: 767px) {
    padding: 50px 0;
  }

  .banner-left,
  .banner-right {
    position: absolute;
    z-index: -1;
    top: 0%;
  }

  .banner-left {
    top: -20%;
    left: 0;
  }
  .banner-right {
    right: 0;
  }

  img {
    display: block;
    max-width: 100%;
  }
  p {
    font-size: 16px;
    line-height: 30px;
    font-weight: 600;
  }
  h2 {
    font-size: 40px;
    font-style: normal;
    line-height: 50px;
  }

  .icon {
    font-size: 40px;
    color: #ffffff;

    background: #7c60d5;
    border-radius: 100%;
    box-shadow: 0px 9px 30px 0px rgba(124, 96, 213, 0.4);
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }
`;

const HomeSectionContainer = styled.div`
  position: relative;
  z-index: 1;
  max-width: 990px;
  padding-right: ${theme.size.space}px;
  padding-left: ${theme.size.space}px;
  margin-left: auto;
  margin-right: auto;
`;

const HomeSectionTitle = styled.div`
  p {
    text-transform: uppercase;
  }
  h2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
  }
`;

const Circle = styled.div`
  font-size: 20px;
  line-height: 50px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -2px;
  margin: 0 auto;
  height: 50px;
  width: 50px;
  border-radius: 90px;
  background: #ffffff;
  color: #1b0e3d;
  text-align: center;
  position: relative;
  z-index: 10;
`;

const WorkItemStyle = styled.div`
  text-align: center;
  position: relative;
  margin-top: 50px;

  @media (max-width: 767px) {
    margin-bottom: 50px;
  }

  .box-content {
    box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.1);
    background: #fff;
    boder-radius: 5px;
    padding: 15px;
    margin-top: 80px;

    img {
      display: block;
      margin: auto;
    }

    @media (max-width: 767px) {
      margin-top: 50px;
    }
  }

  h3 {
    font-size: 25px;
    font-style: normal;
    line-height: 40px;
    margin-top: 20px;
  }

  p {
    font-size: 15px;
    padding-left: 12px;
    padding-right: 12px;
  }

  img {
    display: block;
    max-width: 100%;
    height: 200px;
  }

  &:after,
  &:before {
    content: '';
    position: absolute;
    width: calc(50% + 15px);
    height: 2px;
    background: #ffffff;
    top: 25px;
    background-image: linear-gradient(to right, silver 50%, transparent 0%);
    background-size: 10px 1px;
    background-repeat: repeat-x;
    background-position: 0% bottom;
    animation-name: ${iqWorkBefore};
    animation-duration: 20s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    @media (max-width: 767px) {
      display: none;
    }
  }

  &:before {
    left: -15px;
  }
  &:after {
    right: -15px;
  }

  &.first {
    &:before {
      display: none;
    }
  }
  &.last {
    &:after {
      display: none;
    }
  }
`;

const SolutionsWrap = styled(HomeSection)`
  background: #ddd;
`;
const CounterWrap = styled.div`
  display: flex;
`;

const CounterStyle = styled.div`
  padding: 15px;
  margin-right: 30px;
  display: flex;
  border-radius: 30px;

  &:hover {
    box-shadow: 0px 15px 35px 0px rgba(0, 0, 0, 0.1);
  }

  .number {
    font-size: 30px;

    &:after {
      content: '+';
    }
  }
`;

const ServicesListStyle = styled.div`
  background: #f4f4f4;
  border-radius: 10px;
  padding: 15px;
  display: grid;
  grid-template-columns: ${({ grid }) => `repeat(${grid}, 1fr)`};
  grid-gap: 15px;

  @media (max-width: 767px) {
    overflow-x: auto;
    padding-right: 15px;
  }
`;

const ServiceStyle = styled.div`
  display: flex;
  padding: 30px 15px;
  flex-direction: column;
  text-align: center;
  border-radius: 10px;
  background: ${({ active }) => (active ? '#7c60d5' : '#fff')};
  color: ${({ active }) => (active ? '#fff' : 'inherit')};
  cursor: pointer;

  span {
    display: block;
    font-size: 14px;
    font-weight: 600;
    margin-top: 20px;
  }

  &:hover {
    background: #7c60d5;
    color: #fff;
  }
`;

const ServicesContentTabStyle = styled.div`
  padding: 50px 0;
`;

const Ul = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    display: flex;
    font-weight: 600;
    margin-bottom: 20px;

    &:hover {
      span {
        background: #02d871;
        color: #fff;
      }
    }

    span {
      color: #02d871
      margin-right: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      width: 24px;
      border-radius: 50%;
      border: 1px solid #02d871;
    }
  }

`;

const FeatureCardStyle = styled.div`
  width: 100%;
  padding: 30px 30px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 15px 35px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  transition: all 0.5s ease-out 0s;

  h3 {
    font-size: 24px;
    font-style: normal;
    line-height: 34px;
    margin: 20px 0;
  }
`;

const CardMemberStyle = styled.div`
  border-radius: 10px;
  text-align: center;
  overflow: hidden;

  .content {
    padding: 15px;
    position: relative;
  }

  img {
    display: block;
    width: 100%;
    border-radius: 10px;
  }

  h3 {
    font-size: 24px;
    font-weight: 600;
  }

  .socials {
    background: #fff;
    position: absolute;
    width: 100%;
    bottom: 100%;
    left: -100%;

    padding: 15px 0;
    transition: all 0.5s ease-out 0s;

    span {
      display: inline-block;
      margin: 0 10px;
    }
  }

  &:hover {
    background: #fff;

    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);

    .socials {
      left: 0;
    }
  }
`;

const ItemSliderStyle = styled.div`
  text-align: center;
  color: #fff;
  padding: 15px;

  p {
    margin-top: 30px;
    font-size: 18px;
    font-weight: 600;
    font-style: italic;
  }

  h5 {
    font-size: 24px;
    font-weight: 600;
    color: #fff;
  }
`;

const ArrowStyle = styled.div`
  display: inline-block;
  position: absolute;
  // left: 50%;
  // transform: translateX(-74px);
  ltext-align: center;
  text-indent: inherit;
  z-index: 10;
  bottom: -80px;
  width: auto;
  cursor: pointer;
  transition: opacity 0.3s ease 0s, left 0.3s ease 0s;
  box-shadow: 0px 8px 14.4px 1.6px rgba(0, 0, 0, 0.15);
  font-size: 24px;
  border-radius: 25px;
  width: 74px;
  height: 38px;
  line-height: 38px;
  padding-left: 0px;
  display: inline-block;
  background: #ffffff;
  font-weight: normal;
  text-align: center;
`;

const PostStyle = styled.div`
  font-weight: 16px;
  font-weight: 600;
  padding: 15px;

  .wrap {
  }

  .content {
    padding: 15px;
  }

  img {
    border-radius: 10px;
  }

  .category,
  h5 {
    font-weight: 600;
  }

  h5 {
    font-size: 18px;
    margin: 15px 0;
  }

  .bottom {
    border-top: 1px solid #eee;
    padding: 15px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &:hover {
    .wrap {
      box-shadow: 0px 0px 10px 1px rgba(124, 96, 213, 0.2);
    }
  }
`;

const SliderItemStyle = styled.div`
  text-align: center;

  h2 {
    font-size; 40px;
    font-weight: 700;
  }
`;

const TestimonialWrap = styled(HomeSection)`
  background: #7c60d5 url(${bgw}) no-repeat center;
  background-size: 70%;
`;

export {
  HomeSection,
  HomeSectionContainer,
  HomeSectionTitle,
  Circle,
  WorkItemStyle,
  SolutionsWrap,
  CounterWrap,
  CounterStyle,
  ServicesListStyle,
  ServiceStyle,
  ServicesContentTabStyle,
  Ul,
  FeatureCardStyle,
  CardMemberStyle,
  ItemSliderStyle,
  ArrowStyle,
  PostStyle,
  SliderItemStyle,
  TestimonialWrap
};
