// @flow
import React from 'react';
import { Avatar as AvatarUi } from 'antd';

import avatar from './avatar.jpg';

const Avatar = ({ image, size }: { image?: String, size?: Number }) => {
  return <AvatarUi size={size} src={image} />;
};

Avatar.defaultProps = {
  size: 100,
  image: avatar
};

export default Avatar;
