import styled from 'styled-components';

import { theme } from '../../configs';

const WrapForm = styled.div`
  max-width: 768px;
  width: 95%;
  margin: 50px auto;
  background: #fff;
  h3 {
    font-weight: 700;
    font-size: 14px;
    margin: ${theme.size.space * 2}px 0 ${theme.size.space}px;
  }
`;

const StyleBox = styled.div`
  background: #f5f5f5;
  padding: ${theme.size.space}px;
  border-radius: 5px;
`;

export { WrapForm, StyleBox };
