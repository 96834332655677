import React from 'react';
import { Input, Form } from 'antd';

import { useSubscriber } from '../../api/subscriberApi';
import Button from '../Button';
import { FormSubsStyle, inputStyle } from './style';
import lang from '../Footer/lang';

type FormSubsProps = {
  form: {
    getFieldDecorator: any,
    validateFields: any
  }
};

const FormSubs = ({ form, locale }: FormSubsProps) => {
  const { getFieldDecorator, validateFields } = form;
  const { loading, handleSubscriber } = useSubscriber();

  const handleSubmit = e => {
    e.preventDefault();

    if (loading) {
      return null;
    }

    return validateFields((error, values) => {
      if (!error) {
        const formData = new FormData();
        formData.append('email', values.email);
        handleSubscriber();
      }
    });
  };

  return (
    <FormSubsStyle>
      <Form onSubmit={handleSubmit}>
        <Form.Item style={{ textAlign: 'left' }}>
          {getFieldDecorator('email', {
            rules: [
              {
                type: 'email',
                message: lang.footer3.formEmail.validate.isEmail[locale]
              },
              {
                required: true,
                message: lang.footer3.formEmail.validate.required[locale]
              }
            ]
          })(
            <Input
              placeholder={lang.footer3.formEmail.input[locale]}
              style={inputStyle}
            />
          )}
        </Form.Item>

        <div className="btnForm" onClick={handleSubmit} role="presentation">
          <Button disabled={loading} height={35}>
            {lang.footer3.button[locale]}
          </Button>
        </div>
      </Form>
    </FormSubsStyle>
  );
};
export default Form.create()(FormSubs);
