import React, { useState, useEffect } from 'react';
import { LocationProvider } from '@reach/router';
import { LocaleProvider } from 'antd';
import vi_VN from 'antd/lib/locale-provider/vi_VN';
import moment from 'moment';
import 'moment/locale/vi';
import ReactGA from 'react-ga';

import { history } from './history';
import AppRouter from './routes';
import GlobalStyle from './GlobalStyle';
import { Loading } from './components';

moment.locale('vi');

const tracking_id = 'UA-167413511-1';
ReactGA.initialize(tracking_id);

const App = () => {
  const [loading, setLoading] = useState(true);

  history.listen(window => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    console.log('page=>', window.location.pathname);
  });

  useEffect(() => {
    if (window.FB) {
      setLoading(false);
    }
  }, []);

  if (loading) return <Loading />;

  return (
    <LocationProvider history={history}>
      <GlobalStyle />
      <LocaleProvider locale={vi_VN}>
        <AppRouter />
      </LocaleProvider>
    </LocationProvider>
  );
};

export default App;
