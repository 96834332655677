import Avatar from './Avatar';
import Loading from './Loading';
import Header from './Header';
import Footer from './Footer';
import Container from './Container';
import ContainerFull from './ContainerFull';
import StepLoan from './StepLoan';
import Slider from './Slider';
import GoogleMap from './GoogleMap';
import Head from './Head';

export {
  Avatar,
  Loading,
  Header,
  Footer,
  Container,
  ContainerFull,
  StepLoan,
  GoogleMap,
  Slider,
  Head
};
