// const production = process.env.NODE_ENV;

const production = process.env.NODE_ENV === 'production';

export default {
  URL: production
    ? process.env.REACT_APP_URL_PRODUCTION
    : process.env.REACT_APP_URL_DEV,
  URL_API: process.env.REACT_APP_URL_API,
  GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY
};
